import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsArchive } from "react-icons/bs";
import { useInventoryByArticleQuery } from "../../../services/inventoryApi";

const SupplierProductDetail = ({ localStorageAddFromCart, cartItem }) => {
  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";
  // console.log(cartItem.article_code)
  const [inventoryData, setInventoryData] = useState([]);
  const url = `${BASE_URL}inventory/article_code/${cartItem.article_code}`;

  const getInventory = async () => {
    const result = await axios.get(url);
    setInventoryData(result.data);
  };

  useEffect(() => {
    // const data = inventoryByArticle(cartItem?.article_code)
    // console.log(url)
    getInventory();

    // fetch(url)
    //     .then(res => res.json())
    //     .then(result => setInventoryData(result))

    // const success = axios.get(url);
  }, [cartItem]);
  // console.log(inventoryData.currentQty)
  return (
    <tr key={cartItem?.article_code}>
      {/* <th scope="row">{i++}</thproductList> */}
      <td>{cartItem?.article_code}</td>
    <td title={cartItem?.article_code} className="text-break px-4">
        {cartItem?.name}
      </td>
      {/* {getStockData(cartItem?.article_code)} */}
      <td className="px-2">{parseInt(inventoryData?.currentQty) || 0}</td>
      <td>
        {/* <input
                className="form-check-input"
                onChange={() => localStorageAddFromCart(cartItem)}
                type="checkbox"
                value=""
                id="flexCheckChecked"
              /> */}
        <BsArchive onClick={() => localStorageAddFromCart(cartItem)} />
        {/* <Icons.X
                className="float-end"
                onClick={() => removeFromCart(cartItem.article_code)}
              /> */}
      </td>
    </tr>
  );
};

export default SupplierProductDetail;
