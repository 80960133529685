import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BillerSelcet from "../CustomSelect/BillerSelect";
import { useSaleByBillerQuery } from "../../../services/saleApi";
import { format } from "date-fns";
import {
  selectBiller,
  selectPosData,
  selectDaybookData,
  resetPayment,
  selectVisa,
  selectMasterCard,
  selectBrac,
  selectDbbl,
  selectAmex,
  selectCity,
  selectMtbl,
  selectEbl,
  // selectUcb,
  selectBkash,
  selectRocket,
  selectNagad,
  selectUpay,
  selectOneThousand,
  selectFiveHundred,
  selectTwoHundred,
  selectOneHundred,
  selectFifty,
  selectTwenty,
  selectTen,
  selectFive,
  selectTwo,
  selectOne,
  selectCollectionCash,
  selectCollectionTotal,
  selectCashOut,
  selectOfficeBill,
  selectOpeningCash,
  selectCashInHand,
  selectReceivedCash,
  selectReceivedSum,
  selectCollectionSum,
  selectDifference,
  resetDayBook,
} from "../../../features/posCollectionSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddDayBookMutation,
  useUpdateDayBookMutation,
} from "../../../services/posCollectionApi";

const POSClosingModal = ({ onShow, handleClose }) => {
  const date = format(new Date(), "MM-dd-yyyy");
  const [biller, setBiller] = useState("");
  const [saleData, setSaleData] = useState();
  const { data, error, isSuccess, refetch } = useSaleByBillerQuery({
    startDate: date,
    endDate: date,
    biller: biller,
  });

  const dispatch = useDispatch();
  const posClosingData = useSelector((state) => state.posCollectionReducer);

  console.log("posClosingData", posClosingData);

  const handleSelectBiller = async (value) => {
    dispatch(selectBiller(value?.option));
    setBiller(value?.option);
  };

  useEffect(() => {
    setSaleData([]);
    // setSaleData();
    dispatch(resetDayBook());
    dispatch(selectBiller(biller));

    refetch();
  }, [biller]);

  // collectionTotalRound
  useEffect(() => {
    const collactionTotal =
      parseFloat(posClosingData?.collectionCash) +
      parseFloat(posClosingData?.collectionVisa) +
      parseFloat(posClosingData?.collectionMasterCard) +
      parseFloat(posClosingData?.collectionBrac) +
      parseFloat(posClosingData?.collectionDbbl) +
      parseFloat(posClosingData?.collectionAmex) +
      parseFloat(posClosingData?.collectionCity) +
      parseFloat(posClosingData?.collectionMtbl) +
      parseFloat(posClosingData?.collectionEbl) +
      // parseFloat(posClosingData?.collectionUcb) +
      parseFloat(posClosingData?.collectionBkash) +
      parseFloat(posClosingData?.collectionRocket) +
      parseFloat(posClosingData?.collectionNagad) +
      parseFloat(posClosingData?.collectionUpay);

    dispatch(selectCollectionTotal(collactionTotal));
  }, [
    posClosingData?.collectionCash,
    posClosingData?.collectionVisa,
    posClosingData?.collectionMasterCard,
    posClosingData?.collectionBrac,
    posClosingData?.collectionDbbl,
    posClosingData?.collectionAmex,
    posClosingData?.collectionCity,
    posClosingData?.collectionMtbl,
    posClosingData?.collectionEbl,
    // posClosingData?.collectionUcb,
    posClosingData?.collectionBkash,
    posClosingData?.collectionRocket,
    posClosingData?.collectionNagad,
    posClosingData?.collectionUpay,
  ]);

  // cash in hand
  useEffect(() => {
    const collectionTotal =
      posClosingData?.oneTotal +
      posClosingData?.twoTotal +
      posClosingData?.fiveTotal +
      posClosingData?.tenTotal +
      posClosingData?.twentyTotal +
      posClosingData?.fiftyTotal +
      posClosingData?.oneHundredTotal +
      posClosingData?.twoHundredTotal +
      posClosingData?.fiveHundredTotal +
      posClosingData?.oneThousandTotal;

    dispatch(selectCashInHand(collectionTotal));
  }, [
    posClosingData?.oneTotal,
    posClosingData?.twoTotal,
    posClosingData?.fiveTotal,
    posClosingData?.tenTotal,
    posClosingData?.twentyTotal,
    posClosingData?.fiftyTotal,
    posClosingData?.oneHundredTotal,
    posClosingData?.twoHundredTotal,
    posClosingData?.fiveHundredTotal,
    posClosingData?.oneThousandTotal,
  ]);

  //Available Cash
  useEffect(() => {
    const availableCash =
      parseFloat(posClosingData?.cashInHand) +
      parseFloat(posClosingData?.cashOut) +
      parseFloat(posClosingData?.officeBill);
    const totalReceivedCash =
      parseFloat(posClosingData?.cashInHand) +
      parseFloat(posClosingData?.openingCash) -
      parseFloat(posClosingData?.cashOut) -
      parseFloat(posClosingData?.officeBill);
    dispatch(selectCollectionCash(availableCash));
    dispatch(selectReceivedCash(totalReceivedCash));
  }, [
    posClosingData?.cashInHand,
    posClosingData?.cashOut,
    posClosingData?.officeBill,
    posClosingData?.openingCash,
  ]);

  //Diffrence
  useEffect(() => {
    const diff =
      parseFloat(posClosingData?.collectionTotalRound) -
      parseFloat(posClosingData?.totalRound);
    dispatch(selectDifference(diff));
  }, [posClosingData?.collectionTotalRound, posClosingData?.totalRound]);
  // Set system data
  useEffect(() => {
    // console.log("DATA::>",data[0]);
    setSaleData(data);
    if (data) {
      if (data[0]?._id) {
        console.log("DATA ID::>", data[0]?._id, data[0]?.salesCash);
        dispatch(
          selectDaybookData({
            collectionCash: data[0]?.collectionCash,
            collectionVisa: data[0]?.collectionVisa,
            collectionMasterCard: data[0]?.collectionMasterCard,
            collectionBrac: data[0]?.collectionBrac,
            collectionDbbl: data[0]?.collectionDbbl,
            collectionAmex: data[0]?.collectionAmex,
            collectionCity: data[0]?.collectionCity,
            collectionMtbl: data[0]?.collectionMtbl,
            collectionEbl: data[0]?.collectionEbl,
            collectionUcb: data[0]?.collectionUcb,
            collectionBkash: data[0]?.collectionBkash,
            collectionRocket: data[0]?.collectionRocket,
            collectionNagad: data[0]?.collectionNagad,
            collectionUpay: data[0]?.collectionUpay,

            salesCash: data[0]?.salesCash | 0,
            salesVisa: data[0]?.salesVisa | 0,
            salesMasterCard: data[0]?.salesMasterCard | 0,
            salesBrac: data[0]?.salesBrac | 0,
            salesDbbl: data[0]?.salesDbbl | 0,
            salesAmex: data[0]?.salesAmex | 0,
            salesCity: data[0]?.salesCity | 0,
            salesMtbl: data[0]?.salesMtbl | 0,
            salesEbl: data[0]?.salesEbl | 0,
            // salesUcb: data[0]?.sales?.UCB | 0,
            salesBkash: data[0]?.salesBkash | 0,
            salesRocket: data[0]?.salesRocket | 0,
            salesNagad: data[0]?.salesNagad | 0,
            salesUpay: data[0]?.salesUpay | 0,
            salesPoint: data[0]?.salesPoint | 0,
            salesTotalRound: data[0]?.salesTotalRound | 0,
            dueCash: data[0]?.dueCash | 0,
            dueVisa: data[0]?.dueVisa | 0,
            dueMasterCard: data[0]?.dueMasterCard | 0,
            dueBrac: data[0]?.dueBrac | 0,
            dueDbbl: data[0]?.dueDbbl | 0,
            dueAmex: data[0]?.dueAmex | 0,
            dueCity: data[0]?.dueCity | 0,
            dueMtbl: data[0]?.dueMtbl | 0,
            dueEbl: data[0]?.dueEbl | 0,
            // dueUcb: data[0]?.collection?.UCB | 0,
            dueBkash: data[0]?.dueBkash | 0,
            dueRocket: data[0]?.dueRocket | 0,
            dueNagad: data[0]?.dueNagad | 0,
            dueUpay: data[0]?.dueUpay | 0,
            dueTotalRound: data[0]?.dueTotalRound | 0,
            // Collection Total
            oneThousand: data[0]?.oneThousand | 0,
            oneThousandTotal: data[0]?.oneThousandTotal | 0,
            fiveHundred: data[0]?.fiveHundred | 0,
            fiveHundredTotal: data[0]?.fiveHundredTotal | 0,
            twoHundred: data[0]?.twoHundred | 0,
            twoHundredTotal: data[0]?.twoHundredTotal | 0,
            oneHundred: data[0]?.oneHundred | 0,
            oneHundredTotal: data[0]?.oneHundredTotal | 0,
            fifty: data[0]?.fifty | 0,
            fiftyTotal: data[0]?.fiftyTotal | 0,
            twenty: data[0]?.twenty | 0,
            twentyTotal: data[0]?.twentyTotal | 0,
            ten: data[0]?.ten | 0,
            tenTotal: data[0]?.tenTotal | 0,
            five: data[0]?.five | 0,
            fiveTotal: data[0]?.fiveTotal | 0,
            two: data[0]?.two | 0,
            twoTotal: data[0]?.twoTotal | 0,
            one: data[0]?.one | 0,
            oneTotal: data[0]?.oneTotal | 0,

            cashOut: data[0]?.cashOut, //input
            officeBill: data[0]?.officeBill, //input
            openingCash: data[0]?.openingCash, //input
            difference: data[0]?.difference, //auto Cal

            cashInHand: data[0]?.cashInHand, //-> All physical collected cash
            totalRecivedCash: data[0]?.totalRecivedCash, //-> collectionCash (cashInHand) + officeBill + cashout - opening Cash
            totalRecievedSum: data[0]?.totalRecievedSum,
            totalCollectionSum: data[0]?.totalCollectionSum,

            // Credite sales
            crediteSaleTotal: data[0]?.crediteSaleTotal,
          })
        );
      } else {
        console.log("DATA ID::>", data);

        dispatch(
          selectPosData({
            salesCash: (data?.sales?.cash - data?.sales?.changeAmount) | 0,
            salesVisa: data?.sales?.visa | 0,
            salesMasterCard: data?.sales?.masterCard | 0,
            salesBrac: data?.sales?.BRAC | 0,
            salesDbbl: data?.sales?.DBBL | 0,
            salesAmex: data?.sales?.AMEX | 0,
            salesCity: data?.sales?.CITY | 0,
            salesMtbl: data?.sales?.MTB | 0,
            salesEbl: data?.sales?.EBL | 0,
            // salesUcb: data?.sales?.UCB | 0,
            salesBkash: data?.sales?.bkash | 0,
            salesRocket: data?.sales?.rocket | 0,
            salesNagad: data?.sales?.nagad | 0,
            salesUpay: data?.sales?.upay | 0,
            salesPoint: data?.sales?.point | 0,
            salesTotalRound: data?.sales?.roundedGrossTotal | 0,
            dueCash:
              (data?.collection?.cash - data?.collection?.changeAmount) | 0,
            dueVisa: data?.collection?.visa | 0,
            dueMasterCard: data?.collection?.masterCard | 0,
            dueBrac: data?.collection?.BRAC | 0,
            dueDbbl: data?.collection?.DBBL | 0,
            dueAmex: data?.collection?.AMEX | 0,
            dueCity: data?.collection?.CITY | 0,
            dueMtbl: data?.collection?.MTB | 0,
            dueEbl: data?.collection?.EBL | 0,
            // dueUcb: data?.collection?.UCB | 0,
            dueBkash: data?.collection?.bkash | 0,
            dueRocket: data?.collection?.rocket | 0,
            dueNagad: data?.collection?.nagad | 0,
            dueUpay: data?.collection?.upay | 0,
            dueTotalRound: data?.collection?.dueAmount | 0,
            // Collection Total
          })
        );
      }
    }
  }, [isSuccess, data]);

  const [createDayBook] = useAddDayBookMutation();
  const [updateDayBook] = useUpdateDayBookMutation();

  const handleSaveDayBook = async () => {
    // Submit DayBook Data
    try {
      const dayClosing = await createDayBook(posClosingData);
      console.log(dayClosing);
      handleClose();
    } catch (err) {
      console.log(err);
    }
    // console.log("posClosingData:", posClosingData);
  };

  // console.log("biller", biller, data, saleData);

  return (
    <Modal
      size="xl"
      show={onShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div className="d-flex align-items-center">
          <label className="me-2 text-bold">Select Biller: </label>
          <BillerSelcet
            handleOnchange={handleSelectBiller}
            billerSelect={biller}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-8">
            <b className="py-2">Sales OverView: </b>
            <table className="table table-responsive table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Regular Collection</th>
                  <th scope="col">Dues Collection</th>
                  <th scope="col">Total Collection</th>
                  <th scope="col">Total Recieved</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Cash sales</th>
                  <td>{posClosingData?.salesCash | 0}</td>
                  <td>{posClosingData?.dueCash | 0}</td>
                  <td className="m-0 p-0">{posClosingData?.totalCash}</td>
                  <td>{posClosingData?.salesTotalRound | 0}</td>
                </tr>
                <tr>
                  <th>Visa Card</th>
                  <td>{posClosingData?.salesVisa | 0}</td>
                  <td>{posClosingData?.dueVisa | 0}</td>
                  <td>{posClosingData?.totalVisa || 0}</td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectVisa(e.target.value))}
                      value={posClosingData?.collectionVisa}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Master Card</th>
                  <td>{posClosingData?.salesMasterCard | 0}</td>
                  <td>{posClosingData?.dueMasterCard | 0}</td>
                  <td>{posClosingData?.totalMasterCard | 0}</td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) =>
                        dispatch(selectMasterCard(e.target.value))
                      }
                      value={posClosingData?.collectionMasterCard || 0}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>BRAC</th>
                  <td>{posClosingData?.salesBrac | 0}</td>
                  <td>{posClosingData?.dueBrac | 0}</td>
                  <td>{posClosingData?.totalBrac | 0}</td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectBrac(e.target.value))}
                      value={posClosingData?.collectionBrac || 0}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>DBBL</th>
                  <td>{posClosingData?.salesDbbl | 0}</td>
                  <td>{posClosingData?.dueDbbl | 0}</td>
                  <td>{posClosingData?.totalDbbl | 0}</td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectDbbl(e.target.value))}
                      value={posClosingData?.collectionDbbl || 0}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Amex</th>
                  <td>{posClosingData?.salesAmex | 0}</td>
                  <td>{posClosingData?.dueAmex | 0}</td>
                  <td>{posClosingData?.totalAmex | 0}</td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectAmex(e.target.value))}
                      value={posClosingData?.collectionAmex || 0}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>CITY</th>
                  <td>{posClosingData?.salesCity | 0}</td>
                  <td>{posClosingData?.dueCity | 0}</td>
                  <td>{posClosingData?.totalCity | 0}</td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectCity(e.target.value))}
                      value={posClosingData?.collectionCity || 0}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>MTBL</th>
                  <td>{posClosingData?.salesMtbl | 0}</td>
                  <td>{posClosingData?.dueMtbl | 0}</td>
                  <td>{posClosingData?.totalMtbl | 0}</td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectMtbl(e.target.value))}
                      value={posClosingData?.collectionMtbl || 0}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>EBL</th>
                  <td>{posClosingData?.salesEbl | 0}</td>
                  <td>{posClosingData?.dueEbl | 0}</td>
                  <td>{posClosingData?.totalEbl | 0}</td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectEbl(e.target.value))}
                      value={posClosingData?.collectionEbl || 0}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                {/* <tr>
                  <th>UCB</th>
                  <td>{posClosingData?.sales?.UCB | 0}</td>
                  <td>{posClosingData?.collection?.UCB | 0}</td>
                  <td>
                    {(posClosingData?.sales?.UCB + posClosingData?.collection?.UCB) | 0}
                  </td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectUcb(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr> */}
                <tr>
                  <th>bkash</th>
                  <td>{posClosingData?.salesBkash | 0}</td>
                  <td>{posClosingData?.dueBkash | 0}</td>
                  <td>{posClosingData?.totalBkash | 0}</td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectBkash(e.target.value))}
                      value={posClosingData?.collectionBkash || 0}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Rocket</th>
                  <td>{posClosingData?.salesRocket | 0}</td>
                  <td>{posClosingData?.dueRocket | 0}</td>
                  <td>{posClosingData?.totalRocket | 0}</td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectRocket(e.target.value))}
                      value={posClosingData?.collectionRocket || 0}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Nagad</th>
                  <td>{posClosingData?.salesNagad | 0}</td>
                  <td>{posClosingData?.dueNagad | 0}</td>
                  <td>{posClosingData?.totalNagad | 0}</td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectNagad(e.target.value))}
                      value={posClosingData?.collectionNagad || 0}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Upay</th>
                  <td>{posClosingData?.salesUpay | 0}</td>
                  <td>{posClosingData?.dueUpay | 0}</td>
                  <td>{posClosingData?.totalUpay | 0}</td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectUpay(e.target.value))}
                      value={posClosingData?.collectionUpay || 0}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-end">Total:</th>
                  <td>{posClosingData?.salesTotalRound | 0}</td>
                  <td>{posClosingData?.dueTotalRound | 0}</td>
                  <td>{posClosingData?.totalCollectionSum | 0}</td>
                  <td>{posClosingData?.totalReceivedSum | 0}</td>
                </tr>
                <tr>
                  <th colSpan="4">
                    <div className="d-flex justify-content-between">
                      <div>
                        Todays Credit Sales:{" "}
                        {posClosingData?.crediteSaleTotal | 0}
                      </div>
                      <div>Loyalty Point: {posClosingData?.salesPoint | 0}</div>
                      <div>Difference:</div>
                    </div>
                  </th>
                  {/* <td>{posClosingData?.sales?.dueSale | 0}</td>
                  <th>Loyalty Point</th>
                  <td>{posClosingData?.sales?.point | 0}</td> */}
                  <td>{posClosingData?.difference | 0}</td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className="col-4">
            <b className="py-2">Bank / Card: </b>
            <table className="table table-responsive table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Visa</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Master Card</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>BRAC</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>DBBL</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Amex</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>CITY</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>MTBL</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>EBL</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>UCB</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>bkash</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Rocket</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Nagad</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Upay</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Loyalty/Cupon/Others</th>
                  <td></td>
                </tr>
                <tr>
                  <th className="text-end">Total Sales: </th>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div> */}
          <div className="col-4">
            <b className="py-2">Cash Denomination: </b>
            <table className="table table-responsive table-bordered table-striped">
              <thead>
                <tr>
                  <th className="text-center">Particulars</th>
                  <th>Unit</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="text-center">1000</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) =>
                        dispatch(selectOneThousand(e.target.value))
                      }
                      value={posClosingData?.oneThousand}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData?.oneThousandTotal || 0}</td>
                </tr>
                <tr>
                  <th className="text-center">500</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) =>
                        dispatch(selectFiveHundred(e.target.value))
                      }
                      value={posClosingData?.fiveHundred}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData?.fiveHundredTotal || 0}</td>
                </tr>
                <tr>
                  <th className="text-center">200</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) =>
                        dispatch(selectTwoHundred(e.target.value))
                      }
                      value={posClosingData?.twoHundred}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData?.twoHundredTotal || 0}</td>
                  <td></td>
                </tr>
                <tr>
                  <th className="text-center">100</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) =>
                        dispatch(selectOneHundred(e.target.value))
                      }
                      value={posClosingData?.oneHundred}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData?.oneHundredTotal || 0}</td>
                </tr>
                <tr>
                  <th className="text-center">50</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectFifty(e.target.value))}
                      value={posClosingData?.fifty}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData?.fiftyTotal || 0}</td>
                </tr>
                <tr>
                  <th className="text-center">20</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectTwenty(e.target.value))}
                      value={posClosingData?.twenty}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData?.twentyTotal || 0}</td>
                </tr>
                <tr>
                  <th className="text-center">10</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectTen(e.target.value))}
                      value={posClosingData?.ten}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData?.tenTotal || 0}</td>
                </tr>
                <tr>
                  <th className="text-center">5</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectFive(e.target.value))}
                      value={posClosingData?.five}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData?.fiveTotal || 0}</td>
                </tr>
                <tr>
                  <th className="text-center">2</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectTwo(e.target.value))}
                      value={posClosingData?.two}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData?.twoTotal || 0}</td>
                </tr>
                <tr>
                  <th className="text-center">1</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectOne(e.target.value))}
                      value={posClosingData?.one}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData?.oneTotal || 0}</td>
                </tr>
                <tr>
                  <th colSpan={2}>Cash in Hand</th>
                  <td>{posClosingData?.cashInHand || 0}</td>
                </tr>
                <tr>
                  <th colSpan={2}>Cash out</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectCashOut(e.target.value))}
                      value={posClosingData?.cashOut}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th colSpan={2}>Office Bill</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) =>
                        dispatch(selectOfficeBill(e.target.value))
                      }
                      value={posClosingData?.officeBill}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th colSpan={2}>Opening Cash</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) =>
                        dispatch(selectOpeningCash(e.target.value))
                      }
                      value={posClosingData?.openingCash}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>

                {/* <tr>
                  <th colSpan={2}>Due Collection</th>
                  <td></td>
                </tr> */}
                <tr>
                  <th colSpan={2}>Available Cash</th>
                  <td>{posClosingData?.totalReceivedCash || 0}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="dark" onClick={handleSaveDayBook}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default POSClosingModal;
