import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import { Button, Form, Modal } from "react-bootstrap";
import logo from "../../../logo.png";
import PosCart from "./Parts/PosCart";
import PosFinalizes from "./Parts/PosFinalizes";
import PosFooter from "./Parts/PosFooter";
import PosHeader from "./Parts/PosHeader";
import "./POS.css";
import sendMessage from "../../Utility/smsSystem";
import {
  addToDb,
  getStoredCart,
  removeFromDb,
  customQuantity,
  deleteShoppingCart,
  removeQuantity,
  addQuantity,
} from "../../Utility/cartDB";

import { posFinalizer } from "../../Utility/PosCalculations";
import { Toaster } from "react-hot-toast";
// import useSales from "../../Hooks/useSales";
import { Navigate, useNavigate } from "react-router-dom";
import { signInUser } from "../../Utility/Auth";
import { notify } from "../../Utility/Notify";
import { useForm } from "react-hook-form";
import * as Icons from "heroicons-react";
import useInventory from "../../Hooks/useInventory";
import { v4 as uuidv4 } from "uuid";
import SelectCustomer from "../../Common/CustomSelect/selectCustomer";
import PosSearchProduct from "../../Common/CustomSelect/PosSearchProduct";
import {
  selcetBiller,
  selcetCustomer,
  selcetProductsCart,
  saleFinalize,
  saleNewPoint,
  saleReset,
  selectInvoiceId,
  salesPromoPrice,
  setDue,
  selcetUpdateUser,
  saleDiscount,
} from "../../../features/posSlice";

import {
  useUpdateUserMutation,
  useValidUserMutation,
} from "../../../services/userApi";
// import { dispatch } from "react-hot-toast/dist/core/store";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useUpdatePointCustomerMutation,
} from "../../../services/customerApi";
import { useAddSaleMutation } from "../../../services/saleApi";
import useKeypress from "react-use-keypress";
import Barcode from "react-barcode";
import { format } from "date-fns";
import {
  itemVat,
  itemVatTotal,
  toDecimal,
} from "../../Utility/PosCalculations";

import ReturnModal from "../../Common/Modal/ReturnModal";
import CreateCustomerForm from "../../Common/Form/CreateCustomerForm";
import BillPayModal from "../../Common/Modal/BillPayModal";
import { resetPayment, selectAdmin } from "../../../features/paymentSlice";
import ManagerSelect from "../../Common/CustomSelect/ManagerSelect";
import { useWarehouseQuery } from "../../../services/warehouseApi";

const POS = () => {
  // const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api";
  let i = 1;

  /**
   * ===============================
   * NEW CODE FOR POS SCREEN
   * ===============================
   */

  /**
   * Constants
   * get pos data from redux stroge
   *
  */
 const posSaleData = useSelector((state) => state.posReducer);
 const dispatch = useDispatch();

  const [discountChecked, setDiscountChecked] = useState(false);
  const [usePointChecked, setUsePointChecked] = useState(false);
  const [useDueChecked, setUseDueChecked] = useState(false);
  const [isBill, setIsBill] = useState(false);
  const [isPrint, setIsPrint] = useState(false);
  const [customerPoint, setCustomerPoint] = useState(0);
  const [holdSale, setHoldSale] = useState(false);

  const [show, setShow] = useState(false);
  const [returnShow, setReturnShow] = useState(false);
  const [payDueShow, setPayDueShow] = useState(false);

  const [showPermission, setShowPermission] = useState({
    show: false,
    type: "",
    data: "",
  });

  
  const [manager, setManager] = useState("manager");
  const [pass, setPass] = useState();
  const customerUniqeId = uuidv4();

  const [whName, setWhName] = useState(" ");  
  
  const {data:wh, refetch} = useWarehouseQuery(posSaleData?.warehouse)
  useEffect(() => {
    if (posSaleData?.warehouse) {
      refetch();
    }
  }, [posSaleData?.warehouse, refetch]);

  useEffect(() => {
    if (wh) {
      setWhName(wh?.name);
      refetch();

    }
  }, [wh,refetch]);
  // console.log('warehouse',wh)

// console.log('posSales', posSaleData)
  const [updateHoldSale] = useUpdateUserMutation();


  const handleSelectManager = async (value) => {
    // console.log("option", value);
    setManager(value?.option);
  };

  // UPDATE STATE
  const updateCartState = () => {
    // GET POS CART (New Products)[LocalStorage]
    let cartProduct = getStoredCart();

    // DISPATCH POS CART PRODUCT
    dispatch(selcetProductsCart(cartProduct));

    // GET RETURN CART (Return Product)[REDUX Storege]
    let ReturnProduct = posSaleData.returnProducts;

    // POS FINALIZER POS CART
    const posCartCal = posFinalizer(cartProduct, "cartProduct");

    // POS FINALIZER RETURN CART
    const returnCartCal = posFinalizer(ReturnProduct, "ReturnCartProduct");

    // DISPATCH TO REDUX Storege
    dispatch(
      saleFinalize({
        totalItem: posCartCal.totalItem,
        total: posCartCal.total?.toFixed(2), // - posSaleData.returnCal.total,
        vatAmount: posCartCal.vatAmount?.toFixed(2), // - posSaleData.returnCal.vatAmount,
        grossTotal: parseFloat(
          posCartCal.grossTotal
        )?.toFixed(2),
        grossTotalRound: Math.round(
          posCartCal.grossTotal
        ),
        newPoint:
          posSaleData.point.old +
          Number(posCartCal.newPoint) -
          Number(returnCartCal.newPoint),
        // Return Calculatoin
        reTotal: returnCartCal.total,
        reTotalItem: parseInt(returnCartCal.totalItem),
        reGrossTotal: returnCartCal.grossTotal?.toFixed(2),
        reGrossTotalRound: Math.round(returnCartCal.grossTotal),
        rePoint: returnCartCal.newPoint,
        reVat: returnCartCal.vatAmount,
        promo_discount: posCartCal.promoPrice,
        discount: posSaleData.discount,
      })
    );
    dispatch(salesPromoPrice(posCartCal.promoPrice));

    // let returnProduct = getStoredCart();
    // console.log(Number(posCartCal.newPoint) - Number(returnCartCal.newPoint));

    // console.log(posFinalizer(cartProduct, "cartProduct"));
  };
  /**
   * Set Biller info
   * ===============================
   */

  const user = signInUser();
  useEffect(() => {
    updateCartState();
    // dispatch(selcetBiller({id: user?.id, warehouse: user?.warehouse}));
  }, []);


  useEffect(() => {
    updateCartState();

  }, [posSaleData.discount]);
  /**
   * ADD TO CART
   * add product cart Local storage
   */

  const addToList = async (data, price, qty) => {
    // console.log("addToList", data, price);
    if (data) {
      // console.log("add to list:", data, data.priceList);
      await supplierProductsRef.current.blur();

      // ADD TO DB
      const add = addToDb(data, price, qty);
      // console.log("add to list:", add, data);
      if (add) {
        supplierProductsRef.current.focus();
        updateCartState();
        return true;
      }
    }
  };

  /**
   * Empty Local Cart
   */
  const emptyCart = () => {
    setShowPermission({
      ...showPermission,
      type: "refresh",
      show: true,
      data: "",
    });
    //   if (window.confirm("Refresh Cart! Start New Sale?") === true) {
    //     const deleted = deleteShoppingCart();
    //     if (deleted) {
    //       updateCartState();
    //       notify("Refresh POS SALE", "success");
    //     }
    //   } else {
    //     notify("Refresh Operation Cancelled by POSER", "error");
    //   }
  };

  /**
   * removeFromCart
   * Remove Item Local Cart
   */
  const removeFromCart = (id) => {
    setShowPermission({
      ...showPermission,
      type: "removeFormCart",
      show: true,
      data: id,
    });
    // console.log(id);
    // setPId(id);
    // updateCartState();
  };

  const handleReturn = () => {
    // console.log("return call");
    setShowPermission({
      ...showPermission,
      type: "handelreturn",
      show: true,
      data: "",
    });
  };
  // PAY DUE
  const handlePayDue = () => {
    // console.log("return call");
    setShowPermission({
      ...showPermission,
      type: "handlePayDue",
      show: true,
      data: "",
    });
  };

  // Handle Discount Permission
  const discountCheckbox = () => {
    setShowPermission({
      ...showPermission,
      type: "discountCheck",
      show: true,
      data: "",
    });
  };
  // Handle Point Permission
  const usePointCheckbox = () => {
    setShowPermission({
      ...showPermission,
      type: "usePointCheck",
      show: true,
      data: "",
    });
  };

  // useEffect(() => {
  //   console.log(showPermission);
  //   if (showPermission.show && showPermission.type === "useDueCheck") {
  //     dispatch(setDue(true));
  //   } else {
  //     dispatch(setDue(false));
  //   }
  // }, [showPermission]);

  // Handle Due Permission
  const useDueCheckbox = () => {
    if (posSaleData.due) {
      dispatch(setDue(false));
    } else {
      setShowPermission({
        ...showPermission,
        type: "useDueCheck",
        show: true,
        data: "",
      });
    }
  };
  // console.log("hi")
  // Manager VALID PERMISSION
  const [validUser] = useValidUserMutation();
  const managerPermission = async (e, type, id) => {
    e.preventDefault();
    // console.log(manager, pass);
    const isValid = await validUser({ username: manager, password: pass });
    // console.log(isValid);
    if (isValid?.data?.status === true) {
      // console.log(isValid?.data);
      dispatch(selcetUpdateUser(isValid?.data?.userId));

      switch (type) {
        case "removeFormCart":
          if (removeFromDb(id)) {
            updateCartState();
            setShowPermission({ show: false, type: "", data: "" });
            notify("Authorization Successful!", "success");
          } else {
            notify("Remove Item Unsuccessful!", "error");
          }
          break;
        case "handelreturn":
          // console.log("handel return");
          setShowPermission({ show: false, type: "", data: "" });
          handleReturnShow();
          break;
        case "handlePayDue":
          // console.log("handel Pay Bill");
          setShowPermission({ show: false, type: "", data: "" });
          handlePayDueShow();
          dispatch(selectAdmin(isValid?.data?.userId));
          break;

        case "refresh":
          if (deleteShoppingCart()) {
            updateCartState();
            notify("Refresh POS SALE", "success");
          }
          setShowPermission({ show: false, type: "", data: "" });
          break;
        case "discountCheck":
          setDiscountChecked(!discountChecked);
          setShowPermission({ show: false, type: "", data: "" });
          break;
        case "usePointCheck":
          setUsePointChecked(!usePointChecked);
          setShowPermission({ show: false, type: "", data: "" });
          break;
        case "useDueCheck":
          setUseDueChecked(!useDueChecked);
          dispatch(setDue(!posSaleData.due));

          setShowPermission({ show: false, type: "", data: "" });
          break;

        default:
          console.log(type);
          break;
      }
    }
    setManager("");
    setPass("");
  };

  /**
   * Add Qty
   * Remove Item Local Cart
   */
  const addQuantities = (id) => {
    // console.log(addQuantity(id));
    if (addQuantity(id)) {
      updateCartState();
    }
  };

  /**
   * remove Qty
   * Remove Item Local Cart
   */
  const removeQuantities = (id) => {
    if (removeQuantity(id)) {
      updateCartState();
    }
  };

  /**
   * Custom Qty
   * Custom Item Local Cart
   */
  const handleCustomQty = (e) => {
    const qty = e.target.value > 0 ? parseFloat(e.target.value) : 0;
    const article_code = e.target.attributes.getNamedItem("id").value;
    customQuantity(article_code, qty);
    updateCartState();
  };

  // KEY PRESS
  // control keypress
  // TODO: Replace to Ref()
  const cashAmountField = document.getElementById("cashAmount"); //F2
  const cardAmountField = document.getElementById("cardAmount");
  const cardTypeField = document.getElementById("cardType");
  const mfsAmountField = document.getElementById("mfsAmount");
  const mfsNameField = document.getElementById("mfsName");
  const billPrintButton = useRef(null);

  const supplierProductsRef = useRef(null); //F1
  const customerSelect = useRef(null); //F3

  const salesRerurnButton = useRef(null); //F4
  const holdBillButton = useRef(null); //F6
  const lastBillButton = useRef(null); //F7

  useKeypress("F1", (e) => {
    e.preventDefault();
    supplierProductsRef.current.focus();
  });

  useKeypress("F2", (e) => {
    e.preventDefault();
    cashAmountField.focus();
  });

  useKeypress("F3", (e) => {
    e.preventDefault();
    customerSelect.current.focus();

    // cashAmountField.focus();
    console.log("F3");
  });

  useKeypress("F4", (e) => {
    e.preventDefault();
    // cashAmountField.focus();
    salesRerurnButton.current.click();
    console.log("F4");
  });

  useKeypress("F6", (e) => {
    e.preventDefault();
    // cashAmountField.focus();
    holdBillButton.current.click();
    console.log("F6");
  });

  useKeypress("F7", (e) => {
    e.preventDefault();
    // cashAmountField.focus();
    lastBillButton.current.click();
    console.log("F7");
  });

  useKeypress("Enter", (e) => {
    e.preventDefault();
    const active = document.activeElement;
    if (active === cashAmountField) {
      cardTypeField.focus();
    }
    if (active === cardTypeField) {
      cardAmountField.focus();
    }
    if (active === cashAmountField) {
      cardTypeField.focus();
    }
    if (active === cardAmountField) {
      mfsNameField.focus();
    }
    if (active === mfsNameField) {
      mfsAmountField.focus();
    }
    if (active === mfsAmountField) {
      billPrintButton.current.focus();
    }
    if (active === billPrintButton.current) {
      billPrintButton.current.click();
    }
    // console.log("pressed enter");
  });

  /**
   * NEW CODE FOR POS SCREEN
   */

  // const [pData, setPData] = useState([]);
  const [customer, setCustomer] = useState("62e301c1ee6c8940f6ac1515");
  const [customerName, setCustomerName] = useState("01700000000");
  const [addCustomer] = useAddCustomerMutation();
  const [updateCustomerPoint] = useUpdatePointCustomerMutation();
  // const [getUserPoint] = useCustomerPointQuery();
  const [lastSale, setLastSale] = useState();
  const [lastBillId, setLastBillId] = useState("01717000000");
  const [lastInvoiceId, setLastInvoiceId] = useState("01717000000");
  const [preLoadValue, setPreLoadValue] = useState({
    name: "",
    phone: "",
    address: "",
  });

  // RETURN PRODUCTS
  const [returnProducts, setReturnProducts] = useState([]);
  const [reCal, setReCal] = useState({});
  const [returnInvoice, setReturnInvoice] = useState("");
  const [invoice, setInvoice] = useState({});

  // RETURN PRODUCTS

  const { register, handleSubmit, reset } = useForm({
    defaultValues: preLoadValue,
  });
  // NEW CODE

  // dispatch new point
  useEffect(() => {
    // remaning point
    const restPoint = posSaleData.point.old - posSaleData.paidAmount.point;

    // new point total
    const newTotalPoint =
      restPoint + Math.floor(posSaleData.grossTotalRound / 100);
    // console.log(posSaleData.grossTotalRound / 100);
    // console.log(newTotalPoint);

    // dispatch new point
    newTotalPoint > 0 && dispatch(saleNewPoint(newTotalPoint));
  }, [posSaleData.paidAmount.point]);

  // Hold Sale
  useEffect(() => {
    // console.log(data);
    const getHold = JSON.parse(localStorage.getItem("hold_cart"));
    getHold?.length > 0 ? setHoldSale(true) : setHoldSale(false);
  }, []);
  // UnHold sale
  const getData = JSON.parse(localStorage.getItem("pos_cart"));
  useEffect(() => {
    if (getData) {
      let newCart = getData?.sort((a, b) => b.order - a.order);
      // console.log(newCart);
      // dispatch(selcetProduct(newCart));
    }
  }, [getData, holdSale]);

  const navigate = useNavigate();
  const componentRef = useRef();

  // HANDLE PRINT RECIPT
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      // RESET CART ITEM
      localStorage.setItem("last_sale", JSON.stringify(posSaleData));
      localStorage.setItem("pos_cart", JSON.stringify([]));
      updateCartState();
      dispatch(saleReset());
      setInvoice({});
      setDiscountChecked(false);
      setIsPrint(false);
      // TODO:: reset redux state

      notify("Bill Printed Successful!", "success");
    },
    // onBeforePrint: handleOnBeforeGetContent,
  });

  // const [customer, setCustomer] = useState("Walk in Customer");

  // const searchField = document.getElementById("productSearch");
  useEffect(() => {
    if (isPrint !== false) {
      handlePrint();
      notify("Create Sale Successfully!", "success");
      // setLastSale({});
    } else {
      return;
    }
  }, [isPrint]);
  // console.log(isPrint);
  const [createSale] = useAddSaleMutation();

  // const [customers, setCustomers] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClosePermission = () =>
    setShowPermission({ type: false, type: "", data: "" });
  const handleShowPermission = () =>
    setShowPermission({ ...showPermission, type: false, type: "", data: "" });

  const handleReturnShow = () => setReturnShow(true);
  const handleReturnClose = () => setReturnShow(false);

  const handlePayDueShow = () => setPayDueShow(true);
  const handlePayDueClose = () => {
    dispatch(resetPayment());
    setPayDueShow(false);
  };

  // console.log(show)

  const createNewCustomer = async (customer) => {
    let customerData = customer;
    const message = `সন্মানিত গ্রাহক, আপনাকে TCM সেবার জগতে স্বাগতম। আপনার মেম্বারশিপ আইডি ${customer?.phone}। আগামীতে আরও রোমাঞ্চকর অফার ও সার্ভিস পেতে আমাদের সাথে সংযুক্ত থাকুন। বিস্তারিত - 01332553955`;
    customerData.username = customer?.phone;
    customerData.membership = customer?.phone;
    customerData.status = "active";
    console.log(customerData);

    const newCustomer = {
      name: customer?.name,
      password: customer?.password,
      // email: null,
      phone: customer?.phone,
      username: customer?.phone,
      group: customer?.group,
      batch: customer?.batch,
      membership: customer?.phone,
      address: {
        type: "Home",
        id: customerUniqeId,
        holdingNo: customer?.holdingNo,
        sector: customer?.sector,
        street: customer?.road,
        town: customer?.town,
        city: customer?.city,
        division: customer?.division,
        country: customer?.country,
        zipCode: customer?.zipCode,
      },
      point: customer?.point ? customer?.point : 0,
      status: "active",
    };
    console.log("newCustomer", newCustomer);
    try {
      await addCustomer(newCustomer).then((res) => {
        console.log("customer", res);
        // setCustomer(res.data.id);

        dispatch(
          selcetCustomer({
            customerId: res.data.id,
            point: 0,
            name: customer.name,
            phone: customer.phone,
            group: customer.group,
          })
        );
        // SEND SMS
        sendMessage(customerData?.phone, message);

        notify(`Create Customer as ${customer.name}`, "success");
        // CLOSE POP UP
        handleClose();
        // RESET FORM DATA
        reset({
          name: "",
          phone: "",
          address: "",
        });
        // REFATCH CUSTOMEr DATA
      });
    } catch (err) {
      console.log(err);
    }
    // sendMessage("01332553955", message);

    // console.log(customerDW);
  };

  useEffect(()=>{
    if( posSaleData?.group !== null){
      setDiscountChecked(true)
      // 10 % discount
      const totalAmount =( parseFloat(posSaleData.total) + parseFloat(posSaleData.vat)) 
      // console.log('totalAmount::>', totalAmount)
      const discountAmount =(10*parseFloat(totalAmount)) / 100
      dispatch(saleDiscount(parseFloat(discountAmount.toFixed(2))))
      // console.log('discount Amount::>', discountAmount)

    }else{
      setDiscountChecked(false)
      dispatch(saleDiscount(0))
    }
  },[posSaleData?.group])

  // handlePrintBill
  const handlePrintBill = async (e) => {
    e.preventDefault();
    // console.log(posCalculations);
    // setLastSale({});
    // console.log("before sale:", posSaleData.products);
    // console.log("before sale:", posSaleData);

    if (window.confirm("Are you sure, Print this bill?") === true) {
      // let data = {};
      // console.log("before sale:", posSaleData);
      // die();
      try {
        // Create Sale
        if (posSaleData) {
          if (
            posSaleData.totalItem > 0 ||
            posSaleData.returnCal.totalItem > 0
          ) {
            if (posSaleData.totalReceived >= posSaleData.grossTotalRound) {
              setIsBill(true);
              // console.log(posSaleData);s
              const newSale = await createSale(posSaleData);
              // console.log("new Sale", newSale);
              if (newSale?.data?.status === 200) {
                // console.log(newSale.data);
                dispatch(selectInvoiceId(newSale?.data?.data?.invoiceId));
                setLastInvoiceId(newSale?.data?.data?._id);
                // console.log(lastInvoiceId);
                // TODO:: REPORT RETURN
                if (newSale?.data?.data?.returnInvoice !== "") {
                  const returnReport = `${
                    newSale?.data?.data?.returnProducts?.length
                  } Items are Returned with invoice id ${
                    newSale?.data?.data?.invoiceId
                  } by ${format(new Date(), "MM-dd-yyyy")}`;
                  if (newSale?.data?.data?.returnProducts?.length > 0) {
                    sendMessage("01761131287", returnReport);
                  }
                  // sendMessage("01683723969", returnReport);
                }
                //REPORT RETURN

                // TODO::Add customer point update system backend
                if (posSaleData.customerId !== "62e301c1ee6c8940f6ac1515") {
                  const customerData = {
                    _id: posSaleData.customerId,
                    point: posSaleData.point.new,
                  };

                  const updateCustomer = await updateCustomerPoint(
                    customerData
                  );
                  // console.log("customer", updateCustomer);
                  updateCustomer &&
                    notify(
                      `Update Points ${posSaleData.point.new} for user ${posSaleData.customerName}`,
                      "success"
                    );
                }
                setDiscountChecked(false);
                setUsePointChecked(false);
                setIsPrint(true);
              } else {
                notify("Sale generation failed! Please try again", "error");
                return false;
              }
            } else {
              notify(
                "Total Recieved should be more or equal than Gross Total Round",
                "error"
              );
            }
          } else {
            notify("Must Select Product for create sale", "error");
          }
        }
      } catch (err) {
        console.log(err);
        notify("Bill generation Unsuccessful", "error");
      } finally {
        setIsBill(false);
        return false;
      }
    } else {
      notify("You Cancel the Bill", "error");
    }
  };

  // console.log(payDueShow);

  const handleHoldSale = async () => {
    // const holdCart = carts;
    // TODO:: Create Multiple invoice hold system
    // CHECK HOLD CART
    if (posSaleData?.products?.length > 0) {
      let hold = localStorage.getItem("hold_cart");
      let holdData = JSON.parse(hold);
      if (holdData !== null) {
        holdData = [
          ...holdData,
          { products: posSaleData.products, order: new Date() },
        ];
        console.log(holdData);

        localStorage.setItem("hold_cart", JSON.stringify(holdData));
        await updateHoldSale({ _id: user.id, holdSale: holdData });
      } else {
        localStorage.setItem(
          "hold_cart",
          JSON.stringify([
            { products: posSaleData.products, order: new Date() },
          ])
        );
        await updateHoldSale({
          _id: user.id,
          holdSale: [{ products: posSaleData.products, order: new Date() }],
        });
      }
      localStorage.setItem("pos_cart", JSON.stringify([]));
      // setHoldSale(true);
      dispatch(saleReset());
      updateCartState();
      // console.log(hold); //, holdData)
    } else {
      notify("There is no Products for hold", "error");
    }
  };

  return (
    <div className="pos-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-9">
            <div className="pos-terminal mt-4">
              <div className="row">
                <div className="col-md-4">
                  <Form.Control
                    value={user.name}
                    name="biller_id"
                    readOnly
                  ></Form.Control>
                </div>
                <div className="col-md-4">
                  <Form.Control
                    value={whName}
                    name="warehouse"
                    readOnly
                  ></Form.Control>
                </div>
                <div className="col-md-4">
                  <div className="row g-3">
                    {/* SELECT CUSTOMER */}
                    <div className="col-9">
                      <SelectCustomer customerSelect={customerSelect} />
                    </div>
                    <button
                      type="button"
                      onClick={handleShow}
                      className="col-3 btn btn-outline-dark"
                    >
                      Add +
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                {/* SEARCH PRODUCT */}
                <PosSearchProduct
                  className="searchProduct"
                  addToList={addToList}
                  supplierProductsRef={supplierProductsRef}
                />
                <div className="col-md-12">
                  <table className="table mt-3">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Product</th>
                        <th scope="col">Stock</th>
                        <th scope="col">Price</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Vat</th>
                        <th scope="col">Sub-Total</th>
                      </tr>
                    </thead>
                    <tbody className="cart-list">
                      <PosCart
                        updateCart={updateCartState}
                        invoice={invoice}
                        returnProducts={returnProducts}
                        removeFromCart={removeFromCart}
                        addQuantities={addQuantities}
                        removeQuantities={removeQuantities}
                        handleCustomQty={handleCustomQty}
                        // sales={sales}
                        // setSales={setSales}
                        reCal={reCal}
                      ></PosCart>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <PosHeader logo={logo}></PosHeader>
            <PosFinalizes
              customerPoint={customerPoint}
              billPrintButton={billPrintButton}
              // carts={carts}
              discountValidPermission={managerPermission}
              discountChecked={discountChecked}
              setDiscountChecked={setDiscountChecked}
              setUsePointChecked={setUsePointChecked}
              usePointChecked={usePointChecked}
              useDueChecked={useDueChecked}
              discountCheckbox={discountCheckbox}
              usePointCheckbox={usePointCheckbox}
              useDueCheckbox={useDueCheckbox}
              posFinalizer={posFinalizer}
              handlePrintBill={handlePrintBill}
              // sales={sales}
              // setSales={setSales}
              // setPosCalculations={setPosCalculations}
              reCal={reCal}
              customer={customer}
              isBill={isBill}
            ></PosFinalizes>
          </div>
        </div>
      </div>
      <PosFooter
        emptyCart={emptyCart}
        LastBillId={lastInvoiceId}
        handleHoldSale={handleHoldSale}
        holdSale={holdSale}
        handleReturn={handleReturn}
        handlePayDue={handlePayDue}
        updateCartState={updateCartState}
        salesRerurnButton={salesRerurnButton}
        holdBillButton={holdBillButton}
        lastBillButton={lastBillButton}
      ></PosFooter>

      {/* PRINT BILL */}
      <div className="print-wrapper pt-5 mt-5">
        <div className=" print-area" ref={componentRef}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p className="text-center">
                  <img src={logo} alt="" width="180" className="logo" />
                </p>
                <h5 className="text-center">
                  <b> The Community Magasin</b>
                </h5>
                <p className="text-center info header">
                  <i>BIN 004601696-0102 | Mushak 6.3</i>
                </p>
                <p className="text-center info invoice">
                  Invoice No: {posSaleData.invoiceId}
                </p>
                <p className="info pt-2">
                  Phone: {posSaleData.customerPhone}
                  <span className="float-end">
                    Date: {format(new Date(), "MM-dd-yyyy H:m aaaa")}
                  </span>
                </p>
                <p className="info pt-2">
                  <span className="text-start">
                    Customer: {posSaleData.customerName} |{" "}
                    <span className="float-end">
                      Phone: {posSaleData.customerPhone}{" "}
                    </span>
                  </span>
                  <span className="float-end">
                    <b>{posSaleData.due && "DUE BILL"}</b>
                  </span>
                </p>
                <p className="info pt-2">
                  Biller: {user.name}
                  <span className="float-end">
                    Outlet: <b>{whName || "TCM"}</b>
                  </span>
                </p>
                <p className="text-center pt-2 order_details">ORDER DETAILS</p>
                <table className="table px-2 d-flex-table">
                  <thead>
                    <tr>
                      <td colSpan="3">Item</td>
                      <td>Qty</td>
                      <td>Rate</td>
                      <td>VAT</td>
                      <td>
                        <span className="float-end">Total</span>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {console.log(bill.products)}  */}
                    {posSaleData?.products?.length > 0 ? (
                      <>
                        {posSaleData?.products?.map((item) => (
                          <tr className="d-print-table-row" key={item.id}>
                            <td
                              colSpan="3"
                              style={{
                                textTransform: "capitalize",
                                fontSize: ".7em",
                              }}
                            >
                              {item?.name.toLowerCase().substring(0, 30)}
                              {item?.name?.length > 30 ? "..." : ""}
                            </td>
                            <td style={{ fontSize: ".7em" }}>{item?.qty}</td>
                            <td style={{ fontSize: ".7em" }}>{item?.mrp}</td>
                            <td style={{ fontSize: ".7em" }}>
                              {itemVat(
                                item?.qty && item?.vat,
                                item?.qty,
                                item?.mrp
                              ).toFixed(2)}
                            </td>
                            <td style={{ fontSize: ".7em" }}>
                              <span className="float-end">
                                {itemVatTotal(
                                  item?.vat,
                                  item?.qty,
                                  item?.mrp
                                ).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                        ))}
                        <tr className="d-print-table-row">
                          <td
                            colSpan="3"
                            style={{
                              textTransform: "capitalize",
                              fontSize: ".7em",
                            }}
                          >
                            <span className="float-end">
                              <b>Total: </b>
                            </span>
                          </td>
                          <td style={{ fontSize: ".7em" }}>
                            {posSaleData.totalItem}
                          </td>
                          <td> - </td>
                          <td style={{ fontSize: ".7em" }}>
                            {parseFloat(posSaleData.vat)?.toFixed(2)}
                          </td>
                          <td style={{ fontSize: ".7em" }}>
                            <span className="float-end">
                              {parseFloat(posSaleData.total) +
                                parseFloat(
                                  posSaleData.vat - posSaleData.returnCal.vat
                                )}
                            </span>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <td colSpan="7">No Product in Purchase cart</td>
                      </tr>
                    )}
                    {posSaleData?.returnProducts?.length > 0 && (
                      <>
                        <tr>
                          <th colSpan={3}>
                            <b>Return Products</b>
                          </th>
                          <td colSpan={5} className="text-end">
                            <b>Ref Invoice id:</b>
                            {posSaleData?.returnProducts?.returnInvoice}
                          </td>
                        </tr>
                        {posSaleData?.returnProducts?.map((item) => (
                          <tr className="d-print-table-row" key={item.id}>
                            <td
                              colSpan="3"
                              style={{
                                textTransform: "capitalize",
                                fontSize: ".7em",
                              }}
                            >
                              {item?.name.toLowerCase().substring(0, 30)}
                              {item?.name?.length > 30 ? "..." : ""}
                            </td>
                            <td style={{ fontSize: ".7em" }}>{item?.qty}</td>
                            <td style={{ fontSize: ".7em" }}>{item?.mrp}</td>
                            <td style={{ fontSize: ".7em" }}>
                              {itemVat(
                                item?.qty && item?.vat,
                                item?.qty,
                                item?.mrp
                              ).toFixed(2)}
                            </td>
                            <td style={{ fontSize: ".7em" }}>
                              <span className="float-end">
                                {(item?.qty * item?.mrp).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                        ))}
                        <tr className="d-print-table-row">
                          <td
                            colSpan="3"
                            style={{
                              textTransform: "capitalize",
                              fontSize: ".7em",
                            }}
                          >
                            <span className="float-end">
                              <b>Return Total: </b>
                            </span>
                          </td>
                          <td style={{ fontSize: ".7em" }}>
                            {posSaleData?.returnCal?.totalItem}
                          </td>
                          <td> - </td>
                          <td style={{ fontSize: ".7em" }}>
                            {parseFloat(posSaleData.returnCal.vat)?.toFixed(2)}
                          </td>
                          <td style={{ fontSize: ".7em" }}>
                            <span className="float-end">
                              {posSaleData?.returnCal?.grossTotalRound}
                            </span>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                {/* <p className="bill-info border-bottom border-bottom-dashed">
                  Total Item:{" "}
                  <span className="float-end">{posSaleData.totalItem}</span>
                </p> */}
                {/* {reCal?.totalItem > 0 && (
                  <p className="bill-info border-bottom border-bottom-dashed">
                    Return Item:{" "}
                    <span className="float-end">{reCal?.totalItem}</span>
                  </p>
                )} */}
                {/* <p className="bill-info border-bottom border-bottom-dashed">
                  Total:{" "}
                  <span className="float-end">
                    + {parseFloat(posSaleData?.total)?.toFixed(2)}
                  </span>
                </p> */}
                {/* {console.log(posSaleData?.returnCal?.grossTotalRound)} */}
                {/* {posSaleData?.returnCal?.grossTotalRound > 0 && (
                  <p className="bill-info">
                    Return Total:{" "}
                    <span className="float-end">
                      {posSaleData?.returnCal?.grossTotalRound}
                    </span>
                  </p>
                )} */}
                {/* 02. Discount */}
                {posSaleData?.discount > 0 && (
                  <p className="bill-info border-bottom border-bottom-dashed">
                    Discount:{" "}
                    <span className="float-end">
                      - {posSaleData?.discount ? posSaleData?.discount : 0}
                    </span>
                  </p>
                )}
                {posSaleData?.promo_discount > 0 && (
                  <p className="bill-info">
                    Promo Discount:{" "}
                    <span className="float-end">
                      -{" "}
                      {posSaleData?.promo_discount
                        ? posSaleData?.promo_discount?.toFixed(2)
                        : 0}
                    </span>
                  </p>
                )}
                {/* <p className="bill-info border-bottom border-bottom-dashed">
                  Vat:{" "}
                  <span className="float-end">
                    +{" "}
                    {parseFloat(
                      posSaleData.vat - posSaleData.returnCal.vat
                    )?.toFixed(2)}
                    
                  </span>
                </p> */}
                {/* 01. Total */}
                <p className="bill-info border-bottom border-bottom-dashed">
                  Total:
                  <span className="float-end">
                    {parseFloat(posSaleData?.total)?.toFixed(2)}
                  </span>
                </p>
                {/* 02. Discount */}
                <p className="bill-info border-bottom border-bottom-dashed">
                  Discount:{" "}
                  <span className="float-end">{posSaleData?.discount}</span>
                </p>
                {posSaleData?.promo_discount > 0 && (
                  <p className="bill-info">
                    Promo Discount:{" "}
                    <span className="float-end">
                      {posSaleData?.promo_discount}
                    </span>
                  </p>
                )}
                {/* 03. Vat */}
                <p className="bill-info border-bottom border-bottom-dashed">
                  Vat:{" "}
                  <span className="float-end">
                    {(
                      posSaleData?.vat -
                      (posSaleData?.returnCal?.vatAmount > 0 &&
                        posSaleData?.returnCal?.vatAmount)
                    )?.toFixed(2)}
                  </span>
                </p>
                {/* 04. Return Total */}
                {posSaleData?.returnCal?.total > 0 && (
                  <p className="bill-info border-bottom border-bottom-dashed">
                    Return Total:{" "}
                    <span className="float-end">
                      {posSaleData?.returnCal?.total?.toFixed(2)}
                    </span>
                  </p>
                )}
                {/* 05 Gross Total */}
                <p className="bill-info border-bottom border-bottom-dashed">
                  Gross Total:{" "}
                  <span className="float-end">
                    {parseFloat(posSaleData.grossTotal)?.toFixed(2)}
                  </span>
                </p>
                {/* 06. Gross Total Round */}
                <p className="bill-info border-bottom border-bottom-dashed">
                  Gross Total (Rounding):{" "}
                  <span className="float-end">
                    {posSaleData.grossTotalRound}
                  </span>
                </p>
                {posSaleData?.paidAmount?.cash > 0 && (
                  <p className="bill-info border-bottom border-bottom-dashed">
                    Received Cash:
                    <span className="float-end">
                      {posSaleData?.paidAmount?.cash}
                    </span>
                  </p>
                )}
                {posSaleData?.paidAmount?.card?.amount > 0 && (
                  <p className="bill-info border-bottom border-bottom-dashed">
                    Received{" "}
                    {posSaleData?.paidAmount?.card?.name
                      ? posSaleData?.paidAmount?.card?.name
                      : "Visa"}{" "}
                    Card :
                    <span className="float-end">
                      {posSaleData?.paidAmount?.card.amount}
                    </span>
                  </p>
                )}
                {posSaleData?.paidAmount?.mfs?.amount > 0 && (
                  <p className="bill-info border-bottom border-bottom-dashed">
                    Received {posSaleData?.paidAmount?.mfs?.name}:
                    <span className="float-end">
                      {posSaleData?.paidAmount?.mfs?.amount}
                    </span>
                  </p>
                )}
                {posSaleData?.paidAmount?.point > 0 && (
                  <p className="bill-info border-bottom border-bottom-dashed">
                    Received Point:
                    <span className="float-end">
                      {posSaleData?.paidAmount?.point}
                    </span>
                  </p>
                )}
                <p className="bill-info border-bottom border-bottom-dashed">
                  Total Received:
                  <span className="float-end">
                    {posSaleData?.totalReceived}
                  </span>
                </p>
                <p className="bill-info border-bottom border-bottom-dashed">
                  Change Amount:
                  <span className="float-end">
                    {posSaleData?.changeAmount?.toFixed(2)}
                    BDT
                  </span>
                </p>
                <p className="bill-info text-center ">
                  Previous Point: {posSaleData.point.old} | New Point :
                  {posSaleData.point.new}
                </p>
                <p className="nb">
                  N.B: <i>Sold products will not be returned after 24 hours</i>
                </p>
                <p className="text-center bar-code">
                  {/* {lastSale?.invoiceId && ( */}
                  {lastBillId && (
                    <Barcode
                      value={posSaleData.invoiceId}
                      height="60"
                      width="2"
                      fontSize="10"
                    />
                  )}
                  {/* )} */}
                </p>
                <p className="text-center info footer">www.tcm-bd.com</p>
                <p className="text-center info">
                  <b>Hot Line: 01332553955</b>
                </p>
                <p className="text-center info ">
                  <i>Thank you for shopping with us.</i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* PRINT BILL */}
      <Toaster position="bottom-right" />
      {/* {console.log(posSaleData)} */}
      {/* Add Customer Modal */}
      <Modal show={show} onHide={handleClose}>
        {/* <form onSubmit={handleSubmit(createNewCustomer)}> */}
        <Modal.Header closeButton>
          <Modal.Title>
            <Icons.UserAddOutline /> Create Customer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateCustomerForm onSubmit={createNewCustomer} useForm={useForm} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            className="btn btn-outline-dark"
            variant="outlineDark"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {/* <Button variant="dark" type="submit" className="btn btn-dark ">
            <Icons.Plus> </Icons.Plus> Add Customer
          </Button> */}
        </Modal.Footer>
        {/* </form> */}
      </Modal>
      {/* Return Modal */}
      <ReturnModal
        title="Return Product"
        onShow={returnShow}
        handleClose={handleReturnClose}
        returnProducts={returnProducts}
        setReturnProducts={setReturnProducts}
        updateCart={updateCartState}
        reCal={reCal}
        setReCal={setReCal}
        returnInvoice={returnInvoice}
        setReturnInvoice={setReturnInvoice}
        // handleReturnCustomerSelect={handleReturnCustomerSelect}
        invoice={invoice}
        setInvoice={setInvoice}
      ></ReturnModal>
      {/* Return Modal */}

      <BillPayModal
        title="Due Bill Pay"
        onShow={payDueShow}
        handleClose={handlePayDueClose}
      ></BillPayModal>
      {/* Permission Modal */}
      <Modal show={showPermission.show} onHide={handleClosePermission}>
        {/* {console.log(showPermission)} */}
        <form>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Process</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-3">
              <div className="form-group col-12  mb-3">
                <label htmlFor="inputCustomer">Manager</label>
                {/* <input
                  name="manager"
                  type="text"
                  className="form-control"
                  id="inputCustomer"
                  placeholder="User name"
                  onBlur={(e) => setManager(e.target.value)}
                /> */}
                {/* <select
                  onChange={(e) => setManager(e.target.value)}
                  className="form-control"
                >
                  <option value="manager" selected>
                    Manager
                  </option>
                  <option value="manishankarvakta">Manishankar</option>
                </select> */}
                <ManagerSelect
                  handleOnchange={handleSelectManager}
                  manager={manager}
                />
                <small id="emailHelp" className="form-text text-muted">
                  {/* We'll never share your email with anyone else. */}
                </small>
              </div>
              <div className="form-group col-12  mb-3">
                <label htmlFor="inputMC">Password</label>
                <input
                  type="password"
                  className="form-control"
                  onBlur={(e) => setPass(e.target.value)}
                  placeholder="Password"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="reset"
              className="btn btn-outline-dark"
              variant="outlineDark"
              onClick={handleClosePermission}
            >
              Cancel
            </Button>
            <Button
              variant="dark"
              type="submit"
              className="btn btn-dark "
              onClick={(e) =>
                managerPermission(e, showPermission.type, showPermission.data)
              }
            >
              <Icons.Check> </Icons.Check> Confirm
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default POS;
