import React, { useEffect, useState } from "react";
import * as Icons from "heroicons-react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import powered from "../../../../logo-dark.svg";
import { signInUser } from "../../../Utility/Auth";

import {
  salesAmountRecived,
  saleCash,
  saleCardName,
  saleCardAmount,
  saleMfsName,
  saleMfsAmount,
  salePointAmount,
  saleDiscount,
  selcetBiller,
  selectWarehouse,
} from "../../../../features/posSlice";
import { useDispatch, useSelector } from "react-redux";

const PosFinalizes = React.forwardRef(
  ({
    customer,
    handlePrintBill,
    billPrintButton,
    returnCal,
    usePointChecked,
    usePointCheckbox,
    discountChecked,
    setDiscountChecked,
    discountCheckbox,
    useDueCheckbox,
    useDueChecked,
    isBill,
  }) => {
    const dispatch = useDispatch();
    const saleCal = useSelector((state) => state.posReducer);
    const user = signInUser();

  
    // const posSaleData = useSelector((state) => state.posReducer);

    // const saleCal.due = useSelector((state) => state.posReducer.due);

    /**
     * TOTAL CALCULATIONS
     */
    const totalCalculation = () => {
      const cash = saleCal.paidAmount.cash;
      const usePointAmount = saleCal.paidAmount.point;
      const mfs = saleCal.paidAmount.mfs.amount;
      const card = saleCal.paidAmount.card.amount;
      const promo_discount = saleCal.promo_discount;
      const totalRecivedAmount =
        parseInt(cash) +
        parseInt(card) +
        parseInt(mfs) +
        parseInt(usePointAmount);
      const changeAmount =
        parseInt(totalRecivedAmount) -
        parseInt(saleCal.grossTotalRound) +
        promo_discount;

      dispatch(selcetBiller(user.id));
      dispatch(selectWarehouse(user.warehouse));

      dispatch(salesAmountRecived({ totalRecivedAmount, changeAmount }));
    };

    useEffect(() => {
      totalCalculation();
    }, [
      saleCal.paidAmount.cash,
      saleCal.paidAmount.point,
      saleCal.paidAmount.mfs.amount,
      saleCal.paidAmount.card.amount,
      saleCal.discount,
      saleCal.products,
      saleCal.grossTotalRound,
    ]);

    // console.log(saleCal.returnCal);

    return (
      <div className="card sticky-md-top">
        <div className="card-body mb-2 ">
          <div className="row">
            <div className="col-8">
              <h5 className="card-title">Finalize Sale</h5>
            </div>
            <div className="col-4">
              <b className="text-end">
                {/* <Form.Group className="" controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Due Bill"
                    onChange={useDueCheckbox}
                    checked={saleCal.due}
                    disabled={
                      saleCal.customerId === "62e301c1ee6c8940f6ac1515"
                        ? true
                        : false
                    }
                  />
                </Form.Group> */}
                {/* Use Point:  */}
              </b>
            </div>
          </div>
          <hr />
          <form id="finalizeForm" onSubmit={handlePrintBill}>
            <p className="card-text">
              <b>Total Item: </b>{" "}
              <span className="float-end"> {saleCal?.totalItem}</span>
            </p>
            {saleCal.returnCal?.totalItem > 0 && (
              <p className="card-text">
                <b>Return Item: </b>{" "}
                <span className="float-end">
                  {" "}
                  {parseFloat(saleCal.returnCal.totalItem)}
                </span>
              </p>
            )}

            <p className="card-text">
              <b>Total: </b>
              <span className="float-end">
                {" "}
                {parseFloat(saleCal?.total).toFixed(2)}
                BDT
              </span>
            </p>
            {saleCal.returnCal?.total > 0 && (
              <p className="card-text">
                <b>Return Total: </b>
                <span className="float-end">
                  {" "}
                  {parseFloat(saleCal.returnCal.total).toFixed(2)}BDT
                </span>
              </p>
            )}
            {/* <p className="card-text">
            <b>Discount Amount: </b>{" "}
            <span className="float-end"> {sales.discount} BDT</span>
          </p> */}
            <p className="card-text">
              <b>Vat/Tax Amount: </b>{" "}
              <span className="float-end">
                {" "}
                {parseFloat(saleCal?.vat)?.toFixed(2)}
                BDT
              </span>
            </p>
            <p className="card-text">
              <b>Gross Total: </b>
              <span className="float-end">
                {parseFloat(saleCal?.grossTotal)?.toFixed(2)}
                BDT
              </span>
            </p>
            <p className="card-text">
              <b>Gross Total(Round): </b>
              <span className="float-end">
                {parseFloat(saleCal?.grossTotalRound)?.toFixed(2)}
                BDT
              </span>
            </p>
            <p className="card-text align-middle">
              <div className="row">
                <div className="col-md-6">
                  <b>Cash Received: </b>
                </div>
                <div className="col-md-6">
                  <input
                    type="number"
                    onChange={(e) =>
                      dispatch(
                        saleCash(e.target.value > 0 ? e.target.value : 0)
                      )
                    }
                    id="cashAmount"
                    defaultValue={0}
                    min={0}
                    value={saleCal.paidAmount.cash}
                    className="form-control float-end col-7"
                  />
                </div>
              </div>
            </p>
            <p className="card-text align-middle">
              <div className="row">
                <div className="col-md-3">
                  <b>Card: </b>
                </div>
                <div className="col-md-5">
                  <Form.Select
                    name="cardType"
                    onChange={(e) => dispatch(saleCardName(e.target.value))}
                    id="cardType"
                    aria-label="Default select example"
                  >
                    <option value="visa"> VISA </option>
                    <option value="DBBL"> DBBL </option>
                    <option value="MTB"> MTB </option>
                    <option value="CITY"> CITY </option>
                    <option value="AMEX"> AMEX </option>
                    <option value="EBL"> EBL </option>
                    <option value="BRAC"> BRAC </option>
                    <option value="masterCard"> Master Card</option>
                  </Form.Select>
                </div>
                <div className="col-md-4">
                  <input
                    type="number"
                    defaultValue={0}
                    onChange={(e) =>
                      dispatch(
                        saleCardAmount(
                          parseInt(e.target.value > 0 ? e.target.value : 0)
                        )
                      )
                    }
                    min={0}
                    value={saleCal.paidAmount.card.amount}
                    name="cardAmount"
                    id="cardAmount"
                    className="form-control float-end col-12"
                  />
                </div>
              </div>
            </p>
            <p className="card-text align-middle">
              <div className="row">
                <div className="col-md-3">
                  <b>MFS: </b>
                </div>
                <div className="col-md-5">
                  <Form.Select
                    name="mfsName"
                    onChange={(e) => dispatch(saleMfsName(e.target.value))}
                    id="mfsName"
                    aria-label="Default select example"
                  >
                    <option value="bKash"> Bkash</option>
                    <option value="Nagad"> Nagad </option>
                    <option value="Upay"> Upay </option>
                    <option value="Rocket"> Rocket</option>
                    {/* 
                    <option value="Nagad"> Nagad </option>
                    <option value="Okwallet"> Okwallet</option>
                    <option value="M-cash"> M-cash</option>
                    <option value="Citytouch"> Citytouch </option>
                    <option value="Dmoney"> Dmoney </option>
                    <option value="I-pay"> I-pay</option>
                    <option value="Q-Cash"> Q-Cash</option>
                    <option value="Sure Cash"> Sure Cash</option> */}
                  </Form.Select>
                </div>
                <div className="col-md-4">
                  <input
                    type="number"
                    id="mfsAmount"
                    defaultValue={0}
                    onChange={(e) =>
                      dispatch(
                        saleMfsAmount(e.target.value > 0 ? e.target.value : 0)
                      )
                    }
                    value={saleCal.paidAmount.mfs.amount}
                    name="mfsAmount"
                    className="form-control float-end col-12"
                  />
                </div>
              </div>
            </p>
            {saleCal.point.old > 100 && (
              <p className="card-text align-middle">
                <div className="row">
                  <div className="col-md-4">
                    <b>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check
                          type="checkbox"
                          label="Use Point: "
                          onChange={usePointCheckbox}
                          checked={usePointChecked}
                        />
                      </Form.Group>
                      {/* Use Point:  */}
                    </b>
                  </div>
                  <div className="col-md-4">{saleCal.point.old}</div>
                  <div className="col-md-4">
                    <input
                      disabled={usePointChecked === false && "disabled"}
                      type="number"
                      onChange={(e) =>
                        e.target.value <= saleCal.point.old &&
                        dispatch(
                          salePointAmount(
                            e.target.value > 0 ? e.target.value : 0
                          )
                        )
                      }
                      id="pointAmount"
                      defaultValue={0}
                      min={0}
                      max={saleCal.point.old}
                      value={saleCal.paidAmount.point}
                      className="form-control float-end col-7"
                    />
                  </div>
                </div>
              </p>
            )}
            <p className="card-text align-middle">
              <div className="row">
                <div className="col-md-6">
                  <b>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Discount: "
                        onChange={discountCheckbox}
                        checked={discountChecked}
                      />
                    </Form.Group>
                  </b>
                </div>
                <div className="col-md-6 d-flex gap-2 justify-content-center  align-items-center">
                  {
                  saleCal?.group !== null && '10% '
                  }
                  <input
                    disabled={discountChecked === false && "disabled"}
                    type="number"
                    onChange={(e) =>
                      dispatch(
                        saleDiscount(
                          parseInt(e.target.value > 0 ? e.target.value : 0)
                        )
                      )
                    }
                    id="discount"
                    defaultValue={0}
                    min={0}
                    value={Math.round(saleCal.discount) || "0"}
                    className="form-control float-end"
                  />
                </div>
              </div>
            </p>
            {/* {console.log(saleCal.point)} */}
            <p className="card-text">
              <b>Change Amount: </b>{" "}
              <span className="float-end">
                {saleCal?.changeAmount?.toFixed(2)}
                BDT
              </span>
            </p>
            {saleCal?.promo_discount > 0 && (
              <p className="card-text">
                <b>Promo Discount: </b>{" "}
                <span className="float-end">
                  {saleCal?.promo_discount?.toFixed(2)}
                  BDT
                </span>
              </p>
            )}

            {/* {customer !== "62e301c1ee6c8940f6ac1515" && ( */}
            <p className="card-text">
              <span className="float-start">
                <b>Old Point: </b>
                {saleCal.point.old}
              </span>
              <span className="float-end">
                <b>New Point: </b>
                {saleCal.point.new}
              </span>
            </p>
            {/* )} */}

            <br />
            <hr />
            <p className="text-center m-0">
              <button
                ref={billPrintButton}
                className="btn btn-dark btn-block "
                id="billPrintButton"
                type="submit"
                disabled={isBill}
              >
                <Icons.PrinterOutline className="me-2" size={18} /> Print Bill
              </button>
            </p>
          </form>
        </div>
        <div className="card-footer">
          <div className="text-center">
            <span className="text-sm" style={{ fontSize: "12px", color: "#6c757d" }}>Powered by:</span>
            <Link to="https://techsoulbd.com/">
              <img src={powered} height="25" alt=""/>
            </Link>
          </div>
        </div>
      </div>
    );
  }
);

export default PosFinalizes;
