import { createSlice } from "@reduxjs/toolkit";
import { signInUser } from "../Components/Utility/Auth";

const user = signInUser();

const initialState = {
  // _id: "",
  billerId: "",
  userId: user?.id || "",
  warehouse: user?.warehouse || "",
  // regular sales
  salesCash: 0,
  salesVisa: 0,
  salesMasterCard: 0,
  salesBrac: 0,
  salesDbbl: 0,
  salesAmex: 0,
  salesCity: 0,
  salesMtbl: 0,
  salesEbl: 0,
  // salesUcb: 0,
  salesBkash: 0,
  salesRocket: 0,
  salesNagad: 0,
  salesUpay: 0,
  salesPoint: 0,
  salesTotalRound: 0,
  //Sales in Credits
  dueCash: 0,
  dueVisa: 0,
  dueMasterCard: 0,
  dueBrac: 0,
  dueDbbl: 0,
  dueAmex: 0,
  dueCity: 0,
  dueMtbl: 0,
  dueEbl: 0,
  // dueUcb: 0,
  dueBkash: 0,
  dueRocket: 0,
  dueNagad: 0,
  dueUpay: 0,
  dueTotalRound: 0,
  // Total Receieable
  totalCash: 0,
  totalVisa: 0,
  totalMasterCard: 0,
  totalBrac: 0,
  totalDbbl: 0,
  totalAmex: 0,
  totalCity: 0,
  totalMtbl: 0,
  totalEbl: 0,
  // totalUcb: 0,
  totalBkash: 0,
  totalRocket: 0,
  totalNagad: 0,
  totalUpay: 0,
  totalRound: 0,
  // collections
  collectionCash: 0, //-> available cash
  collectionVisa: 0,
  collectionMasterCard: 0,
  collectionBrac: 0,
  collectionDbbl: 0,
  collectionAmex: 0,
  collectionCity: 0,
  collectionMtbl: 0,
  collectionEbl: 0,
  // collectionUcb: 0,
  collectionBkash: 0,
  collectionRocket: 0,
  collectionNagad: 0,
  collectionUpay: 0,
  collectionTotalRound: 0,
  // Cash denomination
  oneThousand: 0,
  fiveHundred: 0,
  twoHundred: 0,
  oneHundred: 0,
  fifty: 0,
  twenty: 0,
  ten: 0,
  five: 0,
  two: 0,
  one: 0,
  oneThousandTotal: 0,
  fiveHundredTotal: 0,
  twoHundredTotal: 0,
  oneHundredTotal: 0,
  fiftyTotal: 0,
  twentyTotal: 0,
  tenTotal: 0,
  fiveTotal: 0,
  twoTotal: 0,
  oneTotal: 0,

  crediteSaleTotal: 0,
  cashOut: 0,
  officeBill: 0,
  openingCash: 0,
  difference: 0, //-> totalRecievedSum - totalCollectionSum

  cashInHand: 0, //-> All physical collected cash
  totalReceivedCash: 0, //-> cashInHand + cashout - opening Cash - officeBill
  totalReceievedSum: 0,
  totalCollectionSum: 0,

  status: "complete",
};
export const posCollectionSlice = createSlice({
  name: "posCollection",
  initialState: initialState,
  reducers: {
    selectBiller: (state, action) => {
      return {
        ...state,
        billerId: action.payload,
      };
    },
    selectDaybookData: (state, action) => {
      return {
        ...state,
        collectionCash: action.payload.collectionCash,
        collectionVisa: action.payload.collectionVisa,
        collectionMasterCard: action.payload.collectionMasterCard,
        collectionBrac: action.payload.collectionBrac,
        collectionDbbl: action.payload.collectionDbbl,
        collectionAmex: action.payload.collectionAmex,
        collectionCity: action.payload.collectionCity,
        collectionMtbl: action.payload.collectionMtbl,
        collectionEbl: action.payload.collectionEbl,
        collectionUcb: action.payload.collectionUcb,
        collectionBkash: action.payload.collectionBkash,
        collectionRocket: action.payload.collectionRocket,
        collectionNagad: action.payload.collectionNagad,
        collectionUpay: action.payload.collectionUpay,

        salesCash: action.payload.salesCash | 0,
        salesVisa: action.payload.salesVisa | 0,
        salesMasterCard: action.payload.salesMasterCard | 0,
        salesBrac: action.payload.salesBrac | 0,
        salesDbbl: action.payload.salesDbbl | 0,
        salesAmex: action.payload.salesAmex | 0,
        salesCity: action.payload.salesCity | 0,
        salesMtbl: action.payload.salesMtbl | 0,
        salesEbl: action.payload.salesEbl | 0,
        // salesUcb: action.payload.sales?.UCB | 0,
        salesBkash: action.payload.salesBkash | 0,
        salesRocket: action.payload.salesRocket | 0,
        salesNagad: action.payload.salesNagad | 0,
        salesUpay: action.payload.salesUpay | 0,
        salesPoint: action.payload.salesPoint | 0,
        salesTotalRound: action.payload.salesTotalRound | 0,
        dueCash: action.payload.dueCash | 0,
        dueVisa: action.payload.dueVisa | 0,
        dueMasterCard: action.payload.dueMasterCard | 0,
        dueBrac: action.payload.dueBrac | 0,
        dueDbbl: action.payload.dueDbbl | 0,
        dueAmex: action.payload.dueAmex | 0,
        dueCity: action.payload.dueCity | 0,
        dueMtbl: action.payload.dueMtbl | 0,
        dueEbl: action.payload.dueEbl | 0,
        // dueUcb: action.payload.collection?.UCB | 0,
        dueBkash: action.payload.dueBkash | 0,
        dueRocket: action.payload.dueRocket | 0,
        dueNagad: action.payload.dueNagad | 0,
        dueUpay: action.payload.dueUpay | 0,
        dueTotalRound: action.payload.dueTotalRound | 0,
        // Collection Total
        oneThousand: action.payload.oneThousand | 0,
        oneThousandTotal: action.payload.oneThousandTotal | 0,
        fiveHundred: action.payload.fiveHundred | 0,
        fiveHundredTotal: action.payload.fiveHundredTotal | 0,
        twoHundred: action.payload.twoHundred | 0,
        twoHundredTotal: action.payload.twoHundredTotal | 0,
        oneHundred: action.payload.oneHundred | 0,
        oneHundredTotal: action.payload.oneHundredTotal | 0,
        fifty: action.payload.fifty | 0,
        fiftyTotal: action.payload.fiftyTotal | 0,
        twenty: action.payload.twenty | 0,
        twentyTotal: action.payload.twentyTotal | 0,
        ten: action.payload.ten | 0,
        tenTotal: action.payload.tenTotal | 0,
        five: action.payload.five | 0,
        fiveTotal: action.payload.fiveTotal | 0,
        two: action.payload.two | 0,
        twoTotal: action.payload.twoTotal | 0,
        one: action.payload.one | 0,
        oneTotal: action.payload.oneTotal | 0,

        cashOut: action.payload.cashOut | 0, //input
        officeBill: action.payload.officeBill | 0, //input
        openingCash: action.payload.openingCash | 0, //input
        difference: action.payload.difference | 0, //auto Cal

        cashInHand: action.payload.cashInHand | 0, //-> All physical collected cash
        totalRecivedCash: action.payload.totalRecivedCash | 0, //-> collectionCash (cashInHand) + officeBill + cashout - opening Cash
        totalRecievedSum: action.payload.totalRecievedSum | 0,
        totalCollectionSum: action.payload.totalCollectionSum | 0,

        // Credite sales
        crediteSaleTotal: action.payload.crediteSaleTotal | 0,
      };
    },
    selectPosData: (state, action) => {
      return {
        ...state,
        salesCash: action.payload.salesCash,
        salesVisa: action.payload.salesVisa,
        salesMasterCard: action.payload.salesMasterCard,
        salesBrac: action.payload.salesBrac,
        salesDbbl: action.payload.salesDbbl,
        salesAmex: action.payload.salesAmex,
        salesCity: action.payload.salesCity,
        salesMtbl: action.payload.salesMtbl,
        salesEbl: action.payload.salesEbl,
        // salesUcb: action.payload.salesUcb,
        salesBkash: action.payload.salesBkash,
        salesRocket: action.payload.salesRocket,
        salesNagad: action.payload.salesNagad,
        salesUpay: action.payload.salesUpay,
        salesPoint: action.payload.salesPoint,
        salesTotalRound: action.payload.salesTotalRound,
        dueCash: action.payload.dueCash,
        dueVisa: action.payload.dueVisa,
        dueMasterCard: action.payload.dueMasterCard,
        dueBrac: action.payload.dueBrac,
        dueDbbl: action.payload.dueDbbl,
        dueAmex: action.payload.dueAmex,
        dueCity: action.payload.dueCity,
        dueMtbl: action.payload.dueMtbl,
        dueEbl: action.payload.dueEbl,
        // dueUcb: action.payload.dueUcb,
        dueBkash: action.payload.dueBkash,
        dueRocket: action.payload.dueRocket,
        dueNagad: action.payload.dueNagad,
        dueUpay: action.payload.dueUpay,
        dueTotalRound: action.payload.dueTotalRound,
        totalCash:
          Number(action.payload.totalCash || 0) +
          Number(action.payload.dueCash || 0),
        totalVisa:
          Number(action.payload.totalVisa || 0) +
          Number(action.payload.dueVisa || 0),
        totalMasterCard:
          action.payload.salesMasterCard + action.payload.dueMasterCard,
        totalBrac: action.payload.salesBrac + action.payload.dueBrac,
        totalDbbl: action.payload.salesDbbl + action.payload.dueDbbl,
        totalAmex: action.payload.salesAmex + action.payload.dueAmex,
        totalCity: action.payload.salesCity + action.payload.dueCity,
        totalMtbl: action.payload.salesMtbl + action.payload.dueMtbl,
        totalEbl: action.payload.salesEbl + action.payload.dueEbl,
        // totalUcb: action.payload.salesUcb + action.payload.dueUcb,
        totalBkash: action.payload.salesBkash + action.payload.dueBkash,
        totalRocket: action.payload.salesRocket + action.payload.dueRocket,
        totalNagad: action.payload.salesNagad + action.payload.dueNagad,
        totalUpay: action.payload.salesUpay + action.payload.dueUpay,
        totalRound:
          action.payload.salesTotalRound + action.payload.dueTotalRound,
      };
    },
    selectWareHouse: (state, action) => {
      return { ...state, warehouse: action.payload };
    },
    selectVisa: (state, action) => {
      return {
        ...state,
        collectionVisa: action.payload,
      };
    },
    selectMasterCard: (state, action) => {
      return {
        ...state,
        collectionMasterCard: action.payload,
      };
    },
    selectBrac: (state, action) => {
      return {
        ...state,
        collectionBrac: action.payload,
      };
    },
    selectDbbl: (state, action) => {
      return {
        ...state,
        collectionDbbl: action.payload,
      };
    },
    selectAmex: (state, action) => {
      return {
        ...state,
        collectionAmex: action.payload,
      };
    },
    selectCity: (state, action) => {
      return {
        ...state,
        collectionCity: action.payload,
      };
    },
    selectMtbl: (state, action) => {
      return {
        ...state,
        collectionMtbl: action.payload,
      };
    },
    selectEbl: (state, action) => {
      return {
        ...state,
        collectionEbl: action.payload,
      };
    },
    // selectUcb: (state, action) => {
    //   return {
    //     ...state,
    //     collectionUcb: action.payload,
    //   };
    // },
    selectBkash: (state, action) => {
      return {
        ...state,
        collectionBkash: action.payload,
      };
    },
    selectRocket: (state, action) => {
      return {
        ...state,
        collectionRocket: action.payload,
      };
    },
    selectNagad: (state, action) => {
      return {
        ...state,
        collectionNagad: action.payload,
      };
    },
    selectUpay: (state, action) => {
      return {
        ...state,
        collectionUpay: action.payload,
      };
    },
    selectOneThousand: (state, action) => {
      const oneThousand = action.payload;
      return {
        ...state,
        oneThousand: action.payload,
        oneThousandTotal: parseInt(oneThousand) * 1000,
      };
    },
    selectFiveHundred: (state, action) => {
      const fiveHundred = action.payload;
      return {
        ...state,
        fiveHundred: action.payload,
        fiveHundredTotal: parseInt(fiveHundred) * 500,
      };
    },
    selectTwoHundred: (state, action) => {
      const twoHundred = action.payload;
      return {
        ...state,
        twoHundred: action.payload,
        twoHundredTotal: parseInt(twoHundred) * 200,
      };
    },
    selectOneHundred: (state, action) => {
      const oneHundred = action.payload;
      return {
        ...state,
        oneHundred: action.payload,
        oneHundredTotal: parseInt(oneHundred) * 100,
      };
    },
    selectFifty: (state, action) => {
      const fifty = action.payload;
      return {
        ...state,
        fifty: action.payload,
        fiftyTotal: parseInt(fifty) * 50,
      };
    },
    selectTwenty: (state, action) => {
      const twenty = action.payload;
      return {
        ...state,
        twenty: action.payload,
        twentyTotal: parseInt(twenty) * 20,
      };
    },
    selectTen: (state, action) => {
      const Ten = action.payload;
      return {
        ...state,
        ten: action.payload,
        tenTotal: parseInt(Ten) * 10,
      };
    },
    selectFive: (state, action) => {
      const Five = action.payload;
      return {
        ...state,
        five: action.payload,
        fiveTotal: parseInt(Five) * 5,
      };
    },
    selectTwo: (state, action) => {
      const Two = action.payload;
      return {
        ...state,
        two: action.payload,
        twoTotal: parseInt(Two) * 2,
      };
    },
    selectOne: (state, action) => {
      const One = action.payload;
      return {
        ...state,
        one: One,
        oneTotal: parseInt(One) * 1,
      };
    },
    selectCollectionCash: (state, action) => {
      return {
        ...state,
        collectionCash: action.payload,
      };
    },
    selectCollectionTotal: (state, action) => {
      return {
        ...state,
        collectionTotalRound: action.payload,
      };
    },
    selectCashOut: (state, action) => {
      return { ...state, cashOut: action.payload };
    },
    selectOfficeBill: (state, action) => {
      return { ...state, officeBill: action.payload };
    },
    selectOpeningCash: (state, action) => {
      return { ...state, openingCash: action.payload };
    },
    // cashInHand
    selectCashInHand: (state, action) => {
      return { ...state, cashInHand: action.payload };
    },
    // totalReceivedCash
    selectReceivedCash: (state, action) => {
      return { ...state, totalReceivedCash: action.payload };
    },
    // totalRecievedSum
    selectReceivedSum: (state, action) => {
      return { ...state, totalRecievedSum: action.payload };
    },
    // totalCollectionSum
    selectCollectionSum: (state, action) => {
      return { ...state, totalCollectionSum: action.payload };
    },
    selectDifference: (state, action) => {
      return { ...state, difference: action.payload };
    },
    resetDayBook: () => initialState,
  },
});

export const {
  selectBiller,
  selectPosData,
  selectDaybookData,
  resetPayment,
  selectVisa,
  selectMasterCard,
  selectBrac,
  selectDbbl,
  selectAmex,
  selectCity,
  selectMtbl,
  selectEbl,
  // selectUcb,
  selectBkash,
  selectRocket,
  selectNagad,
  selectUpay,
  selectOneThousand,
  selectFiveHundred,
  selectTwoHundred,
  selectOneHundred,
  selectFifty,
  selectTwenty,
  selectTen,
  selectFive,
  selectTwo,
  selectOne,
  selectCollectionCash,
  selectCollectionTotal,
  selectCashOut,
  selectOfficeBill,
  selectOpeningCash,

  selectCashInHand,
  selectReceivedCash,
  selectReceivedSum,
  selectCollectionSum,
  selectDifference,
  resetDayBook,
} = posCollectionSlice.actions;
export const posCollectionReducer = posCollectionSlice.reducer;
