import React, { useEffect, useState } from "react";
import Header from "../Common/Header/Header";
import "./Customer.css";
import * as Icons from "heroicons-react";
import toast, { Toaster } from "react-hot-toast";
import SideBar from "../Common/SideBar/SideBar";
import DatePicker from "react-datepicker";
import { useCustomerPurchaseHistoryQuery } from "../../services/saleApi";
import { useGetCustomerByIdQuery } from "../../services/customerApi";
import LoadingModal from "../Common/Modal/LoadingModal";
import { format, parseISO } from "date-fns";
import { MaterialReactTable } from "material-react-table";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";

const CustomerLedger = () => {
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  const { id } = useParams();

  const [saleData, setSaleData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));

  const { data, refetch } = useCustomerPurchaseHistoryQuery({
    start: startDate,
    end: endDate,
    id,
  });
  const {
    data: customerData,
    isError,
    isLoading,
  } = useGetCustomerByIdQuery({
    id,
  });

  console.log("Customer by DATA::>", customerData);

  const columns = [
    {
      accessorKey: "createdAt",
      header: "Date",
      size: 40,
    },
    { accessorKey: "invoiceNo", header: "Invoice" },
    { accessorKey: "totalItem", header: "Items", size: 30 },
    { accessorKey: "grossTotal", header: "Gross Total", size: 60 },
    { accessorKey: "vat", header: "VAT", size: 40 },
    { accessorKey: "total", header: "Total", size: 60 },
    { accessorKey: "oldPoint", header: "Old Point", size: 40 },
    { accessorKey: "todayPoint", header: "Today Point", size: 40 },
    { accessorKey: "newPoint", header: "New Point", size: 40 },
    { accessorKey: "promoDiscount", header: "Promo Discount", size: 40 },
    { accessorKey: "billerId", header: "Biller" },
    { accessorKey: "totalReceived", header: "Total Received", size: 60 },
    { accessorKey: "changeAmount", header: "Change Amount", size: 60 },
    { accessorKey: "cash", header: "Cash", size: 60 },
    { accessorKey: "cardType", header: "Card Type", size: 60 },
    { accessorKey: "cardAmount", header: "Card Amount", size: 60 },
    { accessorKey: "mfsType", header: "MFS Type", size: 60 },
    { accessorKey: "mfsAmount", header: "MFS Amount", size: 60 },
    { accessorKey: "pointUse", header: "Point Use", size: 60 },
    { accessorKey: "totalReturn", header: "Total Return", size: 60 },
    { accessorKey: "grosstotalReturn", header: "Gross Total Return", size: 60 },
    { accessorKey: "itemReturn", header: "Item Return", size: 60 },
    { accessorKey: "pointReturn", header: "Point Return", size: 60 },
  ];

  const exportHeader = columns.map((col) => ({
    label: col.header,
    key: col.accessorKey,
  }));

  // Sync sale data when API data changes
  useEffect(() => {
    if (data?.sale) {
      setSaleData(data.sale);
    }
    setLoader(false);
  }, [data]);

  // Refetch data when date range or id changes
  useEffect(() => {
    if (startDate && endDate && id) {
      setLoader(true);
      refetch();
    }
  }, [startDate, endDate, id, refetch]);

  return (
    <div className="container-fluid">
      <LoadingModal title="Please Wait" onShow={loader} />
      <div className="row">
        <div className="col-md-2">
          <SideBar />
        </div>
        <div className="col-md-10">
          <Header title="Customer Ledger" />
          {/* Date Picker Section */}
          <div className="row my-3">
            <div className="col-md-5">
              <form>
                <div className="date-picker d-flex align-items-center gap-2">
                  <DatePicker
                    selected={new Date(startDate)}
                    className="form-control"
                    onChange={(date) =>
                      setStartDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  <DatePicker
                    selected={new Date(endDate)}
                    className="form-control"
                    onChange={(date) =>
                      setEndDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                </div>
              </form>
            </div>
          </div>
          {/* Customer Details Section */}
          <div className="row mb-3">
            <div className="col-md-12">
              <div className="card p-3">
                <h4 className="mb-2">Customer Details</h4>
                <hr />
                <div className="row">
                  <div className="col-md-3">
                    <p>
                      <b>Name:</b> {customerData?.name}
                    </p>
                    <p>
                      <b>Username:</b> {customerData?.username}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <p>
                      <b>Phone:</b> {customerData?.phone}
                    </p>
                    <p>
                      <b>Email:</b> {customerData?.email}
                    </p>
                  </div>
                  <div className="col-md-2">
                    <p>
                      <b>Points:</b> {customerData?.point}
                    </p>
                    <p>
                      <b>Type:</b> {customerData?.type}
                    </p>
                  </div>
                  <div className="col-md-2">
                    <p>
                      <b>Membership:</b> {customerData?.membership}
                    </p>
                    <p>
                      <b>Group:</b> {customerData?.group}
                    </p>
                  </div>
                  <div className="col-md-2">
                    <p>
                      <b>Batch:</b> {customerData?.batch}
                    </p>
                    <p>
                      <b>Status:</b> {customerData?.status}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Sales Data Table Section */}
          <div className="row">
            <div className="col-md-12">
              <MaterialReactTable
                columns={[
                  {
                    accessorKey: "serial", // Dummy key for serial numbers
                    header: "#",
                    size: 0,
                    Cell: ({ row }) => row.index + 1, // Adds serial number starting from 1
                  },
                  ...columns, // Include your existing columns here
                ]}
                data={saleData?.length > 0 ? saleData : []} // Pass empty array if no data
                initialState={{
                  density: "compact",
                  pagination: {
                    pageSize: 20, // Default page size to 20
                  },
                }}
                renderTopToolbarCustomActions={() => (
                  <div className="d-flex gap-3 flex-wrap align-items-center">
                    {saleData?.length > 0 && ( // Show download button only if data exists
                      <CSVLink
                        className="btn btn-dark"
                        data={saleData}
                        headers={exportHeader}
                        filename={`${
                          customerData?.name
                        }_Purchase_History_${today.toDateString()}.csv`}
                      >
                        <Icons.DownloadOutline size={22} /> Download CSV
                      </CSVLink>
                    )}
                  </div>
                )}
                enableRowSelection={false} // Disable row selection if needed
                renderEmptyRow={() => (
                  <div className="text-center text-muted">
                    <p>No data available</p> {/* Message shown when no data */}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <Toaster position="bottom-right" />
    </div>
  );
};

export default CustomerLedger;
