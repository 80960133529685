import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Header from "../Common/Header/Header";
import "./Customer.css";
import * as Icons from "heroicons-react";
import { useForm } from "react-hook-form";
import CsvImporter from "../Common/CsvImporter/CsvImporter";
import toast, { Toaster } from "react-hot-toast";
import SideBar from "../Common/SideBar/SideBar";
import {
  useAddCustomerMutation,
  useCustomerQuery,
  useCustomerPagenationQuery,
  useCustomersQuery,
  useDeleteCustomerMutation,
  useCustomerCountQuery,
  useCustomersExportQuery,
} from "../../services/customerApi";
import { apiUniqueErrHandle } from "../Utility/Utility";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { notify } from "../Utility/Notify";
import CustomerExportModal from "../Common/Modal/CustomerExportModal";
import LoadingModal from "../Common/Modal/LoadingModal";
import { v4 as uuidv4 } from "uuid";
import CreateCustomerForm from "../Common/Form/CreateCustomerForm";
import { signInUser } from "../Utility/Auth";
import WareHouseDW from "../Common/CustomSelect/WareHouseDW";
import GroupModal from "../Common/Modal/GorupModal";

const Customer = () => {
  let navigate = useNavigate();
  const id = uuidv4();
  const [Addcustomer] = useAddCustomerMutation();
  const [deleteCustomer] = useDeleteCustomerMutation();
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [q, setQ] = useState("");
  const [warehouse, setWarehouse] = useState("allWh");
  const CustomersView = useCustomersQuery();

  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);

  const auth = signInUser();

  const { data, error, isLoading, isFetching, isSuccess, refetch } =
    useCustomerPagenationQuery({
      page,
      size,
      warehouse,
      q,
    });
  useEffect(() => {
    // console.log(data);
    data ? setLoader(false) : setLoader(true);
  }, [data]);
  // console.log(data);
  // const [Datacustomer] = useCustomerQuery(`${_id}`);
  const [onShowCustomer, setOnShowCustomer] = useState(false);
  const handleCloseCustomer = () => setOnShowCustomer(false);
  const [onShowGroup, setOnShowGroup] = useState(false);
  const handleCloseGroup = () => setOnShowGroup(false);

  const { data: customer, isSuccess: isSuccessCustomer } =
    useCustomersExportQuery({ warehouse });

  const { register, handleSubmit, reset } = useForm();
  // console.log(customer)
  let i = 1;
  const onSubmit = async (customer) => {
    console.log("customer Create", customer);
    let newCustomer = {};

    newCustomer = {
      name: customer?.name,
      password: customer?.password,
      phone: customer?.phone,
      email: customer?.email,
      username: customer?.username,
      membership: customer?.membership,
      address: [
        {
          type: "Home",
          id: id,
          holdingNo: customer?.holdingNo,
          sector: customer?.sector,
          street: customer?.road,
          town: customer?.town,
          city: customer?.city,
          division: customer?.division,
          country: customer?.country,
          zipCode: customer?.zipCode,
        },
      ],
      point: customer?.point ? customer?.point : 0,
      type: customer?.type,
      group: customer?.group,
      batch: customer?.batch,
      warehouse: auth?.warehouse,
      status: customer?.status,
    };
    console.log("newCustomer", newCustomer);
    setLoader(true);

    try {
      // console.log(data);
      const response = await Addcustomer(newCustomer);
      if (response) {
        console.log(response);
        if (response?.error) {
          apiUniqueErrHandle(response);
        } else {
          reset({
            name: "",
            username: "",
            password: "",
            membership: "",
            address: "",
            holdingNo: "",
            sector: "",
            street: "",
            town: "",
            city: "",
            division: "",
            country: "",
            zipCode: "",
            phone: "",
            email: "",
            type: "",
            warehouse: auth?.warehouse,
            status: "active",
          });
          notify("Customer Create Successful!", "success");

          // console.log(response?.data?.message);
          return navigate("/customer");
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const deleteHandler = async (id) => {
    setLoader(true);
    try {
      const confirm = window.confirm("Are you Sure? Delete this Customer?");
      if (confirm) {
        const res = await deleteCustomer(id);
        if (res) {
          // TODO::
          // add error hendaler for delete error
          console.log(res);
        } else {
          console.log("Delete Operation Canceled by Customer!");
          return;
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const handleReset = () => {
    reset({
      name: "",
      phone: "",
      address: "",
    });
  };

  // get totel product count
  const pageCountQuery = useCustomerCountQuery();
  useEffect(() => {
    const { data } = pageCountQuery;
    setPageCount(data);
  }, [pageCountQuery]);

  const handleDataLimit = (e) => {
    setSize(parseInt(e.target.value));
    setPageNo(getPageNumber);
    refetch();
  };

  // console.log(pageCount, size);
  const getPageNumber = () => {
    const cont = Math.ceil(parseInt(pageCount) / parseInt(size));
  };

  const handleSearch = (e) => {
    setQ(e.target.value);
    refetch();
  };

  const handlePageClick = (data) => {
    setPage(parseInt(data.selected));
    setPageNo(getPageNumber);
    refetch();
  };
  const handleCustomerExport = () => {
    setOnShowCustomer(true);
    console.log(onShowCustomer);
    console.log(customer);
  };
  const handleGroup = () => {
    setOnShowGroup(true);
    console.log(onShowGroup);
    console.log("");
  };
  useEffect(() => {
    refetch();
  }, [warehouse]);

  useEffect(() => {
    if (auth?.type === "admin") {
      setWarehouse("allWh");
    } else {
      setWarehouse(auth?.warehouse);
    }
  }, []);

  const handleOnchangeWareHouseFrom = (e) => {
    if (e.option !== "no-warehouse") {
      setWarehouse(e.option);
    } else {
      setWarehouse("allWh");
    }
  };
  return (
    <div>
      <div className="container-fluid">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Customer"></Header>
            <div className="row">
              <div className="col-md-4">
                <form>
                  <div className="input-group mt-2 mb-2">
                    {/* Data Limit Dropdown */}
                    <select
                      className="form-select"
                      onChange={(e) => handleDataLimit(e)}
                      style={{
                        borderTopLeftRadius: "0.5rem",
                        borderBottomLeftRadius: "0.5rem",
                      }}
                    >
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                      <option value="250">250</option>
                    </select>

                    {/* Search Input */}
                    <input
                      className="form-control"
                      type="text"
                      onKeyUp={(e) => handleSearch(e)}
                      placeholder="Search..."
                      style={{
                        borderTopRightRadius: "0.5rem",
                        borderBottomRightRadius: "0.5rem",
                      }}
                    />
                  </div>
                </form>

                {/* Warehouse Selector */}
                <div>
                  {auth?.type === "admin" && (
                    <WareHouseDW
                      id="warehouse"
                      name="warehouse"
                      handleOnChange={handleOnchangeWareHouseFrom}
                      className="form-control mt-2"
                      style={{
                        borderRadius: "0.5rem",
                      }}
                    />
                  )}
                </div>
              </div>

              {/* Pagination */}
              <div className="col-md-4">
                <nav
                  aria-label="Page navigation example"
                  className="mt-2"
                  style={{
                    // backgroundColor: "#f8f9fa",
                    // borderRadius: "0.5rem",
                    padding: "0.5rem",
                  }}
                >
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(parseInt(pageCount) / parseInt(size))}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={6}
                    onPageChange={handlePageClick}
                    containerClassName={
                      "pagination pt-0 pb-2 justify-content-center"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                  ></ReactPaginate>
                </nav>
              </div>

              {/* Export Buttons */}
              <div className="col-md-4 gap-3 row mt-2 align-items-start justify-content-end">
                <Button
                  className="btn btn-dark col-md-4"
                  onClick={() => handleGroup()}
                  style={{
                    borderRadius: "0.5rem",
                  }}
                >
                  <Icons.PencilAltOutline size={20} /> Group
                </Button>

                <Button
                  className="btn btn-dark col-md-7 "
                  onClick={() => handleCustomerExport()}
                  style={{
                    borderRadius: "0.5rem",
                  }}
                >
                  <Icons.DownloadOutline size={20} /> Export Details
                </Button>
              </div>

              <div className="col-md-7">
                <Table hover striped>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Membership</th>
                      <th scope="col">Point</th>
                      <th scope="col" className="text-end">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 ? (
                      data?.map((Customer) => (
                        <tr key={Customer._id}>
                          <th scope="row">{i++}</th>
                          <td>{Customer.name}</td>
                          <td>
                            {Customer.phone ? Customer.phone : "No Phone"}
                          </td>
                          <td>{Customer.type}</td>
                          <td>{Customer.point}</td>
                          <td className="text-end">
                          <Link to={`/customer/ledger/${Customer._id}`}>
                            <Icons.DocumentTextOutline
                              className="icon-edit"
                              size={20}
                            />
                            </Link>
                            <Link to={`/customer/update/${Customer._id}`}>
                              <Icons.PencilAltOutline
                                className="icon-edit"
                                size={20}
                              ></Icons.PencilAltOutline>
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={9}> No Customer Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-5 ">
                <div className="card mt-2">
                  <div className="card-header">
                    <h5 className="card-title">
                      <Icons.UserAddOutline /> Create Customer
                    </h5>
                  </div>
                  <div className="card-body">
                    <CreateCustomerForm
                      onSubmit={onSubmit}
                      // handleReset={handleReset}
                      useForm={useForm}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="bottom-right" />
      <CustomerExportModal
        onShow={onShowCustomer}
        handleClose={handleCloseCustomer}
        exportCustomer={customer}
      ></CustomerExportModal>
      <GroupModal
        onShow={onShowGroup}
        handleClose={handleCloseGroup}
        // exportCustomer={Group}
      ></GroupModal>
    </div>
  );
};

export default Customer;
