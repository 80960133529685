import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { signInUser } from "./Auth";

const RequireAuth = ({ allowRoles }) => {
  const location = useLocation();
  const auth = signInUser();
  // console.log("Require Auth", auth?.type, allowRoles.includes(auth?.type));

  return allowRoles.includes(auth?.type) ? (
    <>
      {/* {console.log("user role matched")} */}
      <Outlet />
    </>
  ) : auth?.id ? (
    <Navigate to="/dashboard" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
