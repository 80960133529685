import React, { useEffect, useState } from "react";
import Header from "../../Common/Header/Header";
import SideBar from "../../Common/SideBar/SideBar";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import * as Icons from "heroicons-react";
import { Icon } from "@mui/material";
import POSClosingModal from "../../Common/Modal/POSClosingModal";
import { signInUser } from "../../Utility/Auth";
import WareHouseDW from "../../Common/CustomSelect/WareHouseDW";
import { useDayBooksQuery } from "../../../services/posCollectionApi";
import LoadingModal from "../../Common/Modal/LoadingModal";
import { Helmet } from "react-helmet";

const DayBook = () => {
  const auth = signInUser();
  const [date, setDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [warehouse, setWarehouse] = useState("allWh");
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  // console.log("Date data::>",date)
  const { data, error, isLoading, isFetching, isSuccess, refetch } =
    useDayBooksQuery({ date, warehouse });
  const singleData = data?.data;
  const totalData = data?.total;
  // console.log('Day book Data::>',totalData?.totalSalesBkash)

  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);

  useEffect(() => {
    isFetching ? setLoader(true) : setLoader(false);
  }, [data, isLoading, isFetching]);

  console.log("Daybook", data);

  useEffect(() => {
    refetch();
  }, [date, warehouse]);

  useEffect(() => {
    if (auth?.type === "admin") {
      setWarehouse("allWh");
    } else {
      setWarehouse(auth?.warehouse);
    }
  }, []);

  const handleOnchangeWareHouseFrom = (e) => {
    if (e.option !== "no-warehouse") {
      setWarehouse(e.option);
    } else {
      setWarehouse("allWh");
    }
  };

  return (
    <div>
      <LoadingModal
        title={"Please Wait"}
        onShow={loader}
        handleClose={handleLoaderClose}
      ></LoadingModal>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TCM-POS-SALE</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Day Book"></Header>
            <div className="row my-2">
              {/* Left Section: Date and Warehouse */}
              <div className="col-md-7 d-flex align-items-center gap-3">
                {/* Date Picker */}
                <div className="d-flex align-items-center">
                  <b className="me-2">Date:</b>
                  <DatePicker
                    selected={new Date(date)}
                    className="form-control"
                    onChange={(date) =>
                      setDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                </div>

                {/* Warehouse Dropdown (Visible for Admins) */}
                {auth?.type === "admin" && (
                  <div className="d-flex align-items-center">
                    <b className="me-2">Warehouse:</b>
                    <WareHouseDW
                      id="warehouse"
                      name="warehouse"
                      handleOnChange={handleOnchangeWareHouseFrom}
                      className="form-control warehouse-dropdown "
                    />
                  </div>
                )}
              </div>

              {/* Right Section: Action Buttons */}
              <div className="col-md-5 d-flex justify-content-end gap-2">
                <button className="btn btn-dark btn-sm">
                  <Icons.PrinterOutline /> Print
                </button>
                <button
                  className="btn btn-dark btn-sm"
                  onClick={handleOpenModal}
                >
                  POS Closing
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <table className="table table-responsive table-bordered table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">System</th>
                      <th scope="col">Recieved</th>
                      <th scope="col">Difference</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Cash sales</th>
                      <td>{totalData?.salesCash || 0}</td>
                      <td>{totalData?.totalRecivedCash || 0}</td>
                      <td>{totalData?.difference || 0}</td>
                    </tr>
                    <tr>
                      <th>BRAC</th>
                      <td>{totalData?.salesBrac || 0}</td>
                      <td>{totalData?.collectionBrac || 0}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>Visa</th>
                      <td>{totalData?.salesVisa || 0}</td>
                      <td>{totalData?.collectionVisa || 0}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>Master Card</th>
                      <td>{totalData?.salesMasterCard || 0}</td>
                      <td>{totalData?.collectionMasterCard || 0}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>DBBL</th>
                      <td>{totalData?.salesDbbl || 0}</td>
                      <td>{totalData?.collectionDbbl || 0}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>Amex</th>
                      <td>{totalData?.salesAmex || 0}</td>
                      <td>{totalData?.collectionAmex || 0}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>CITY</th>
                      <td>{totalData?.salesCity || 0}</td>
                      <td>{totalData?.collectionCity || 0}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>MTBL</th>
                      <td>{totalData?.salesMtbl || 0}</td>
                      <td>{totalData?.collectionMtbl || 0}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>EBL</th>
                      <td>{totalData?.salesEbl || 0}</td>
                      <td>{totalData?.collectionEbl || 0}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>Bkash</th>
                      <td>{totalData?.salesBkash || 0}</td>
                      <td>{totalData?.collectionBkash || 0}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>UCB</th>
                      <td>{totalData?.salesUcb || 0}</td>
                      <td>{totalData?.collectionUcb || 0}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>Rocket</th>
                      <td>{totalData?.salesRocket || 0}</td>
                      <td>{totalData?.collectionRocket || 0}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>Nagad</th>
                      <td>{totalData?.salesNagad || 0}</td>
                      <td>{totalData?.collectionNagad || 0}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>Upay</th>
                      <td>{totalData?.salesUpay || 0}</td>
                      <td>{totalData?.collectionUpay || 0}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>E-CommerceOnline</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>

                    <tr>
                      <th>Loyalty/Cupon/Others</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <th>Due Bill</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <th>Due Collection</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <th className="text-end">Total Sales: </th>
                      <td>{totalData?.salesTotalRound || 0}</td>
                      <td>{totalData?.collectionCash || 0}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-4">
                <table className="table table-responsive table-bordered table-striped">
                  <thead>
                    <tr>
                      <th className="text-center">Particulars Name</th>
                      <th>Unit</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="text-center">1000</th>
                      <td>{totalData?.oneThousand || 0}</td>
                      <td>{totalData?.oneThousandTotal || 0}</td>
                    </tr>
                    <tr>
                      <th className="text-center">500</th>
                      <td>{totalData?.fiveHundred || 0}</td>
                      <td>{totalData?.fiveHundredTotal || 0}</td>
                    </tr>
                    <tr>
                      <th className="text-center">200</th>
                      <td>{totalData?.twoHundred || 0}</td>
                      <td>{totalData?.twoHundredTotal || 0}</td>
                    </tr>
                    <tr>
                      <th className="text-center">100</th>
                      <td>{totalData?.oneHundred || 0}</td>
                      <td>{totalData?.oneHundredTotal || 0}</td>
                    </tr>
                    <tr>
                      <th className="text-center">50</th>
                      <td>{totalData?.fifty || 0}</td>
                      <td>{totalData?.fiftyTotal || 0}</td>
                    </tr>
                    <tr>
                      <th className="text-center">20</th>
                      <td>{totalData?.twenty || 0}</td>
                      <td>{totalData?.twentyTotal || 0}</td>
                    </tr>
                    <tr>
                      <th className="text-center">10</th>
                      <td>{totalData?.ten || 0}</td>
                      <td>{totalData?.tenTotal || 0}</td>
                    </tr>
                    <tr>
                      <th className="text-center">5</th>
                      <td>{totalData?.five || 0}</td>
                      <td>{totalData?.fiveTotal || 0}</td>
                    </tr>
                    <tr>
                      <th className="text-center">2</th>
                      <td>{totalData?.two || 0}</td>
                      <td>{totalData?.twoTotal || 0}</td>
                    </tr>
                    <tr>
                      <th className="text-center">1</th>
                      <td>{totalData?.one || 0}</td>
                      <td>{totalData?.oneTotal || 0}</td>
                    </tr>
                    <tr>
                      <th colSpan={2}>Total cash</th>
                      <td>{totalData?.cashInHand || 0}</td>
                    </tr>
                    <tr>
                      <th colSpan={2}>Cash out</th>
                      <td>{totalData?.cashOut || 0}</td>
                    </tr>
                    <tr>
                      <th colSpan={2}>Office Bill</th>
                      <td>{totalData?.officeBill || 0}</td>
                    </tr>
                    <tr>
                      <th colSpan={2}>Opening Cash</th>
                      <td>{totalData?.openingCash || 0}</td>
                    </tr>
                    <tr>
                      {/* confuse value */}
                      <th colSpan={2}>Due Bill</th>
                      <td>{totalData?.dueTotalRound || 0}</td>
                    </tr>
                    <tr>
                      {/* confuse value */}
                      <th colSpan={2}>Due Collection</th> 
                      <td>{totalData?.dueTotalRound || 0}</td>
                    </tr>
                    <tr>
                      <th colSpan={2}>Total Cash Sales</th>
                      <td>{totalData?.salesTotalRound || 0}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* Individually biller Data  */}
            <div>
              {singleData && singleData.length > 0 ? (
                singleData.map((data, index) => (
                  <div className="row py-4">
                    <h3>{data?.billerId?.name}</h3>
                    <div className="col-4">
                      <b className="py-2">Sales OverView: </b>
                      <table className="table table-responsive table-bordered table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Cash sales</th>
                            <td>{data?.salesCash || 0}</td>
                          </tr>
                          <tr>
                            <th>BRAC</th>
                            <td>{data?.salesBrac || 0}</td>
                          </tr>
                          <tr>
                            <th>VISA</th>
                            <td>{data?.salesVisa || 0}</td>
                          </tr>
                          <tr>
                            <th>DBBL</th>
                            <td>{data?.salesDbbl || 0}</td>
                          </tr>
                          <tr>
                            <th>Amex</th>
                            <td>{data?.salesAmex || 0}</td>
                          </tr>
                          <tr>
                            <th>Master Card</th>
                            <td>{data?.salesMasterCard || 0}</td>
                          </tr> 
                          <tr>
                            <th>CITY</th>
                            <td>{data?.salesCity || 0}</td>
                          </tr>
                          <tr>
                            <th>MTBL</th>
                            <td>{data?.salesMtbl || 0}</td>
                          </tr>
                          <tr>
                            <th>EBL</th>
                            <td>{data?.salesEbl || 0}</td>
                          </tr>
                          {/* <tr>
                            <th>UCB</th>
                            <td>{data?.salesCash || 0}</td>
                          </tr> */}
                          <tr>
                            <th>bkash</th>
                            <td>{data?.salesBkash || 0}</td>
                          </tr>
                          <tr>
                            <th>Rocket</th>
                            <td>{data?.salesRocket || 0}</td>
                          </tr>
                          <tr>
                            <th>Nagad</th>
                            <td>{data?.salesNagad || 0}</td>
                          </tr>
                          <tr>
                            <th>Upay</th>
                            <td>{data?.salesUpay || 0}</td>
                          </tr>
                          <tr>
                            <th>Loyalty/Cupon/Others</th>
                            <td></td>
                          </tr>
                          <tr>
                            <th>Due Bill</th>
                            <td></td>
                          </tr>
                          <tr>
                            <th>Due Collection</th>
                            <td></td>
                          </tr>
                          <tr>
                            <th className="text-end">Total Sales: </th>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-4">
                      <b className="py-2">Bank / Card: </b>
                      <table className="table table-responsive table-bordered table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>BRAC</th>
                            <td>{data?.collectionBrac || 0}</td>
                          </tr>
                          <tr>
                            <th>VISA</th>
                            <td>{data?.collectionVisa || 0}</td>
                          </tr>
                          <tr>
                            <th>DBBL</th>
                            <td>{data?.collectionDbbl || 0}</td>
                          </tr>
                          <tr>
                            <th>Amex</th>
                            <td>{data?.collectionAmex || 0}</td>
                          </tr>
                          <tr>
                            <th>Master Card</th>
                            <td>{data?.collectionMasterCard || 0}</td>
                          </tr>
                          <tr>
                            <th>CITY</th>
                            <td>{data?.collectionCity || 0}</td>
                          </tr>
                          <tr>
                            <th>MTBL</th>
                            <td>{data?.collectionMtbl || 0}</td>
                          </tr>
                          <tr>
                            <th>EBL</th>
                            <td>{data?.collectionEbl || 0}</td>
                          </tr>
                          <tr>
                            <th>UCB</th>
                            <td>{data?.collectionBrac || 0}</td>
                          </tr>
                          <tr>
                            <th>bkash</th>
                            <td>{data?.collectionBkash || 0}</td>
                          </tr>
                          <tr>
                            <th>Rocket</th>
                            <td>{data?.collectionRocket || 0}</td>
                          </tr>
                          <tr>
                            <th>Nagad</th>
                            <td>{data?.collectionNagad || 0}</td>
                          </tr>
                          <tr>
                            <th>Upay</th>
                            <td>{data?.collectionUpay || 0}</td>
                          </tr>
                          <tr>
                            <th>Loyalty/Cupon/Others</th>
                            <td></td>
                          </tr>
                          <tr>
                            <th className="text-end">Total Sales: </th>
                            <td>{data?.collectionTotalRound || 0}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-4">
                      <b className="py-2">Cash: </b>
                      <table className="table table-responsive table-bordered table-striped">
                        <thead>
                          <tr>
                            <th className="text-center">Particulars Name</th>
                            <th>Unit</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th className="text-center">1000</th>
                            <td>{data?.oneThousand || 0}</td>
                            <td>{data?.oneThousandTotal || 0}</td>
                          </tr>
                          <tr>
                            <th className="text-center">500</th>
                            <td>{data?.fiveHundred || 0}</td>
                            <td>{data?.fiveHundredTotal || 0}</td>
                          </tr>
                          <tr>
                            <th className="text-center">200</th>
                            <td>{data?.twoHundred || 0}</td>
                            <td>{data?.twoHundredTotal || 0}</td>
                          </tr>
                          <tr>
                            <th className="text-center">100</th>
                            <td>{data?.oneHundred || 0}</td>
                            <td>{data?.oneHundredTotal || 0}</td>
                          </tr>
                          <tr>
                            <th className="text-center">50</th>
                            <td>{data?.fifty || 0}</td>
                            <td>{data?.fiftyTotal || 0}</td>
                          </tr>
                          <tr>
                            <th className="text-center">20</th>
                            <td>{data?.twenty || 0}</td>
                            <td>{data?.twentyTotal || 0}</td>
                          </tr>
                          <tr>
                            <th className="text-center">10</th>
                            <td>{data?.ten || 0}</td>
                            <td>{data?.tenTotal || 0}</td>
                          </tr>
                          <tr>
                            <th className="text-center">5</th>
                            <td>{data?.five || 0}</td>
                            <td>{data?.fiveTotal || 0}</td>
                          </tr>
                          <tr>
                            <th className="text-center">2</th>
                            <td>{data?.two || 0}</td>
                            <td>{data?.twoTotal || 0}</td>
                          </tr>
                          <tr>
                            <th className="text-center">1</th>
                            <td>{data?.one || 0}</td>
                            <td>{data?.oneTotal || 0}</td>
                          </tr>
                          <tr>
                            <th colSpan={2}>Total cash</th>
                            <td>{data?.cashInHand || 0}</td>
                          </tr>
                          <tr>
                            <th colSpan={2}>Cash out</th>
                            <td>{data?.cashOut || 0}</td>
                          </tr>
                          <tr>
                            <th colSpan={2}>Office Bill</th>
                            <td>{data?.officeBill || 0}</td>
                          </tr>
                          <tr>
                            <th colSpan={2}>Opening Cash</th>
                            <td>{data?.openingCash || 0}</td>
                          </tr>
                          <tr>
                            <th colSpan={2}>Due Bill</th>
                            <td></td>
                          </tr>
                          <tr>
                            <th colSpan={2}>Due Collection</th>
                            <td></td>
                          </tr>
                          <tr>
                            <th colSpan={2}>Total Cash Sales</th>
                            <td>{data?.totalSalesCash || 0}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))
              ) : (
                <div>No data available</div>
              )}
            </div>
          </div>
        </div>
        <POSClosingModal onShow={open} handleClose={handleCloseModal} />
      </div>
    </div>
  );
};

export default DayBook;
