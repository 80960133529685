import axios from "axios";
import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import { signInUser } from "../../Utility/Auth";
// import { useProductSearchQuery } from "../../../services/searchApi.tsx";
// import { supplierProducts } from "../../../features/supplierSlice";
// import { connect } from "react-redux";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";

const auth = signInUser();
// const dispatch = useDispatch();

class SelectProduct extends Component {
  
  constructor(props, context, dispatch) {
    super(props, context);
    this.state = {
      selectedOption: {},
      normalSelectOption: null,
      qString: null,
    };
  }
  result = [];

  // CHECK if PNP products
  pnpMc = [54, 59, 61];
  // pnpMc = [53, 54, 57, 59, 61, 65];
  qty = 1;
  searchProduct = async (e) => {};

  fetchData = async (inputValue, callback) => {
    this.setState({ qString: inputValue });

    let cancelToken;
    let articleCode = inputValue;

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Cancel The Previous Request");
    }

    // Convert article code to string
    const articleCodeStr = articleCode.toString();

    // Check if any element in the array matches the start of the article code
    const startsWithAny = this.pnpMc.some((item) =>
      articleCodeStr.startsWith(item.toString())
    );

    if (startsWithAny) {
      const code = articleCode.substring(0, 7);
      const wBefore = articleCode.substring(7, 9);
      const wAfter = articleCode.substring(9, 12);
      this.qty = `${wBefore}.${wAfter}`;

      // console.log("code:", code);
      // console.log("Part 2:", wBefore);
      // console.log("Part 3:", wAfter);
      // console.log("Qty:", this.qty);
      console.log("Article code starts with one of the array items.");
    } else {
      console.log("Article code does not start with any of the array items.");
    }

    // console.log("input", articleCode);
    cancelToken = axios.CancelToken.source();
    this.result = await axios.get(`${BASE_URL}product/search/${articleCode}`, {
    // this.result = await axios.get(`${BASE_URL}product/searchwh/${auth?.warehouse}/${articleCode}`, {
    cancelToken: cancelToken.token,
    });

    // console.log("search Query:", this.state.qString, this.result.data);

    let tempArray = [];
    if (this.result.data.length === 1) {
      const pro = this.result.data[0];
      if (this.state.qString !== null) {
        if (pro.priceList.length === 1) {
          // console.log("single", pro.priceList);
          if (
            this.props.addToList(
              this.result.data[0],
              pro.priceList[0]?._id,
              this.qty
            )
          ) {
            this.qty = 1;
            this.setState({ qString: null });
            this.selectRef.current.focus();
            return;
          }
        } else {
          // console.log("multiple", pro.priceList);
          pro.priceList.map((element) => {
            // Loop through price
            if (element.status !== false) {
              tempArray.push({
                label: `${pro.name} - [ ${pro.article_code} ] - ${element?.mrp} BDT - Stock: ${element?.stock} ${pro.unit}`,
                value: pro._id,
                price: element._id,
              });
            }
          });
        }
      }
    } else if (this.result.data.length > 1) {
      // get match more than one
      // console.log(this.result.data);
      const resultData = this.result.data;
      // console.log("Result Data",resultData);
      resultData.forEach((element) => {
        // console.log(element);
        const activePrices = element?.priceList?.filter(
          (price) => price.status === "active"
        );
        // console.log(
        //   `${element?.name} - [ ${element?.article_code} ] ${activePrices[0]?.mrp}BDT-Stock: ${element?.stock} ${element?.unit}`
        // );
        tempArray.push({
          label: `${element?.name} - [ ${element?.article_code} ] ${activePrices[0]?.mrp|| 0}BDT-Stock: ${element?.stock || 0} ${element?.unit}`,
          value: element?._id,
          price: activePrices[0]?._id,
        });
      });
    } else {
      tempArray.push({
        label: `Please Scan the Bar Code`,
        value: `please select`,
      });
    }
    // console.log("TEMP:", tempArray);
    callback(tempArray);
  };

  getValue = async (data, price) => {
    let cancelToken;

    // console.log("get value", data);

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Cancel The Previous Request");
    }

    cancelToken = axios.CancelToken.source();
    const result = await axios.get(`${BASE_URL}product/${data}`, {
    // const result = await axios.get(`${BASE_URL}product/searchcart/${data}/${auth?.warehouse}`, {
      cancelToken: cancelToken.token,
    });

    // console.log("result.data", result.data);

    if (this.props.addToList(result.data, price, this.qty) === false) {
      this.setState({ qString: null });
      this.qty = 1;
      this.selectRef.current.focus();
      return;
    }
  };

  onSearchChange = (selectedOption) => {
    if (selectedOption) {
      // console.log(selectedOption);
      this.setState({
        selectedOption,
      });
      // console.log(selectedOption);
      this.getValue(selectedOption.value, selectedOption.price);
    }
  };
  handleChange = (normalSelectOption) => {
    this.setState({ normalSelectOption });
  };

  selectRef = React.createRef();

  render() {
    return (
      <AsyncSelect
        // ref={this.props.searchField}
        value={this.state.selectedOption}
        loadOptions={this.fetchData}
        placeholder="Product Search"
        key={this.fetchData}
        onChange={(e) => {
          this.onSearchChange(e);
        }}
        defaultOptions={true}
        classNamePrefix="react-select"
        innerRef={this.selectRef}
        ref={this.props.supplierProductsRef}
      />
    );
  }
}

// const mapDispatchProps = supplierProducts(this.state.selectedOption);
export default SelectProduct;
